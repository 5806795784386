export const crossFilterMap = [
  {
    key: 'analysisName',
    filter: 'analysis',
    alias: 'analysisId',
  },
  { key: 'buildingName',
    filter: 'BID',
    alias: 'buildingId',
  },
  { key: 'buildingClassName',
    filter: 'BuildingClassID',
    alias: 'buildingClassId',
  },
  { key: 'buildingTypeName',
    filter: 'BuildingTypeID',
    alias: 'buildingTypeId',
  },
  {
    key: 'dataSourceName',
    filter: 'dataSource',
    alias: 'dataSourceId',
  },
  {
    key: 'equipmentClassName',
    filter: 'equipmentClass',
    alias: 'equipmentClassId',
  },
  {
    key: 'equipmentTypeName',
    filter: 'equipmentType',
    alias: 'equipmentTypeId',
  },
  {
    key: 'equipmentName',
    filter: 'equipment',
    alias: 'equipmentId',
  },
  { key: 'organizationName',
    filter: 'UnitID',
    alias: 'organizationId',
  },
  {
    key: 'pointName',
    filter: 'point',
    alias: 'pid',
  },
  {
    key: 'pointClassName',
    filter: 'pointClass',
    alias: 'pointClassId',
  },
  {
    key: 'pointTypeName',
    filter: 'pointType',
    alias: 'pointTypeId',
  },
  {
    key: 'diagnosticDate',
    filter: 'diagnosticDate',
    alias: 'diagnosticDate',
  },
  {
    key: 'taskOpenedDate',
    filter: 'taskOpenedDate',
    alias: 'taskOpenedDate',
  },
  {
    key: 'taskCompletionDate',
    filter: 'taskCompletionDate',
    alias: 'taskCompletionDate',
  },
  {
    key: 'taskModifiedDate',
    filter: 'taskModifiedDate',
    alias: 'taskModifiedDate',
  },
  {
    key: 'reporter',
    filter: 'taskReporter',
    alias: 'reporterUid',
  },
  {
    key: 'assignee',
    filter: 'taskAssignee',
    alias: 'assignedUid',
  },
  {
    key: 'status',
    filter: 'taskStatus',
    alias: 'status',
  },
];

export const getFilterNameByAlias = (alias) => (crossFilterMap.find((item) => item.alias === alias) || {}).filter;

export const getFilterAliasByKey = (key) => (crossFilterMap.find((item) => item.key === key) || {}).alias;

export default {
  crossFilterMap,
  getFilterNameByAlias,
  getFilterAliasByKey,
};
