import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageEquipment from './ManageEquipment';
import withEquipmentModuleWrapper from '../../hocs/withEquipmentModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'equipmentModuleJsonFilters',
  'equipmentModule',
  'isAllFiltersLoading',
  'userSettings',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageEquipmentContainer = React.memo(ManageEquipment, arePropsEqual);

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  equipmentModule: state.equipmentModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
});

export default withEquipmentModuleWrapper(connect(mapStateToProps)(ManageEquipmentContainer));
