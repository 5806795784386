import initialState from '../reducers/initialState';

const userModules = {
  Tasks: 1,
  Diagnostics: 2,
  Home: 3,
  Building: 4,
  Equipment: 5,
  Point: 6,
};

const getModuleIds = () => Object.values(userModules);

const getModuleDefaultJsonFilters = (moduleId) => {
  switch (moduleId) {
    case userModules.Tasks:
      return [...initialState.taskModule.config.jsonFilters];
    case userModules.Diagnostics:
      return [...initialState.diagnosticsModule.config.jsonFilters];
    case userModules.Home:
      return [...initialState.homeModule.config.jsonFilters];
    case userModules.Building:
      return [...initialState.buildingModule.config.jsonFilters];
    case userModules.Equipment:
      return [...initialState.equipmentModule.config.jsonFilters];
    case userModules.Point:
      return [...initialState.pointModule.config.jsonFilters];
    default:
      return [];
  }
};

export default {
  userModules,
  getModuleIds,
  getModuleDefaultJsonFilters,
};
