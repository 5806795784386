/**
 *  * Created by Stewart Gordon on 11/7/2017.
 */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavMenu from './NavMenu';
import NavSubmenu from './NavSubmenu';
import { NavLink, useHistory } from 'react-router-dom';
import useWindowResize from '../../hooks/useWindowResize';
import { useSelector } from 'react-redux';

const Nav = ({ navData, toggleMenu, isOpen, toggleLeftNav, toggleResize, resources, dashboards }) => {
  const ref = useRef();
  const history = useHistory();
  const innerSize = useWindowResize();
  const permissions = useSelector((state) => state.permissions);

  const [lastMenuItem, setLastMenuItem] = useState(null);
  const [hoveringSubMenu, setHoveringSubMenu] = useState(false);
  const [pinnedSubMenu, setPinnedSubMenu] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [spaceForTop, setSpaceForTop] = useState(52);
  const [spaceForBottom, setSpaceForBottom] = useState(80);
  const [scrollBarWidth, setScrollbarWidth] = useState(0);
  const [hasHoveredOutMenu, setHasHoveredOutMenu] = useState(false);

  const innerHeight = window.innerHeight;
  const scrollHeight = document.documentElement.scrollHeight;

  const isMobile = innerSize.current.width < 769;

  useEffect(() => {
    if (isMobile && isOpen) {
      ref.current.focus();
    }
  }, [isMobile, isOpen]);
  /*  const itemHasResources = (item) => {
    if (item.resources.length && item.resources.some((el) => resources.indexOf(el) !== -1)) {
      return true;
    }
    return false;
  };*/

  useEffect(() => {
    if (navData.length > 0) {
      navData.forEach((parentItem) => {
        const dummyParent = Object.assign({}, parentItem);
        if (dummyParent.name === 'Dashboards') {
          dummyParent.menu = dashboards.map((subNav) => (
            {
              url: `/${subNav.udid}`,
            }
          ));
          if (dashboards.length > 0 && dashboards.find((dashboard) => `/${dashboard.udid}` === window.location.pathname)) setActiveTab('Dashboards');
        }
        if (dummyParent.menu.length > 0) {
          dummyParent.menu.forEach((subItem) => {
            if (window.location.pathname === subItem.url) {
              setActiveTab(dummyParent.name);
            }
          });
        }
      });
    }

    setTimeout(() => {
      handleSettingSubnav();
    }, 500);
  }, []);

  useEffect(() => {
    if (dashboards.length > 0 && dashboards.find((dashboard) => `/${dashboard.udid}` === window.location.pathname)) setActiveTab('Dashboards');
  }, [dashboards]);

  useEffect(() => {
    if (!pinnedSubMenu && (scrollHeight > innerHeight) && scrollBarWidth !== 0) {
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`;
      document.documentElement.style.backgroundColor = '#f5f5f5';
      setScrollbarWidth(0);
    }
  }, [scrollBarWidth]);

  useEffect(() => {
    if (hasHoveredOutMenu && !hoveringSubMenu && !pinnedSubMenu && lastMenuItem) {
      toggleMenu(lastMenuItem, 'out');
      setLastMenuItem(null);
      setHoveringSubMenu(false);
      setHasHoveredOutMenu(false);
      toggleResize();
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.paddingRight = '0';
    }
  }, [hasHoveredOutMenu]);

  const handleHideLeftNav = () => {
    toggleLeftNav(false, isMobile);
    handleSubMenuOut();
    setActiveTab(null);
  };

  //function for setting the top and bottom of subnav unpinned
  const handleSettingSubnav = () => {
    const currentScrollY = window.scrollY;

    //setting the top
    if (currentScrollY < 52) {
      setSpaceForTop(52 - currentScrollY);
    } else {
      setSpaceForTop(0);
    }

    //setting the height of the subnav to simulate the bottom
    if (Math.round(currentScrollY) + innerHeight >= scrollHeight - 80) {
      if (currentScrollY < 52) {
        //when both header and footer are within view
        setSpaceForBottom((52 - currentScrollY) + (164 - scrollHeight + innerHeight + currentScrollY));
      } else {
        //when only footer is in view
        setSpaceForBottom(164 - scrollHeight + innerHeight + currentScrollY);
      }
    } else {
      if (currentScrollY < 52) {
        //when only header is in view
        setSpaceForBottom(137 - currentScrollY);
      } else {
        //when neither are in view
        setSpaceForBottom(86);
      }
    }

    //remove scrollability if scroll is possible
    if (!pinnedSubMenu && (scrollHeight > innerHeight)) {
      const dummyScrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      setScrollbarWidth(dummyScrollBarWidth);
    }
  };

  const hasDashboardReadPermission = (item) => item.resources.some((r) => resources.includes(r));
  const hasEveryResourcePermission = (item) => item.resources.every((r) => resources.includes(r));
  const hasTasksPermission = (item) => resources.find((res) => res === item.resources[0]);
  const hasAdminPermission = (item) => subMenuHasResources(item);
  const hasAssetsPermission = (item) => hasEveryResourcePermission(item);

  const subMenuHasResources = (item) => {
    if (item.name === 'Dashboards' || item.name === 'Assets') {
      return true;
    }
    if (item.menu) {
      const reducer = (accumulator, currentValue) => accumulator.concat(currentValue);
      const resids = item.menu.map((el) => el.resources).reduce(reducer, []);
      if (resids.some((el) => resources.indexOf(el) !== -1)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const renderNav = (item) => {
    const navArray = [];
    const hasSubmenu = subMenuHasResources(item);

    const addNavMenu = (item, isActiveFromSubMenu) => {
      navArray.push(
        <NavMenu
          key={item.name}
          parentItem={item}
          toggleNav={(name, type) => handleIn(name, type)}
          toggleLeftNav={handleHideLeftNav}
          activeFromSubMenu={isActiveFromSubMenu}
        />,
      );
    };

    const isActive = (tabName, regex = null) => tabName === activeTab && (!regex || regex.test(history.location.pathname));

    const handleItem = (permissionCheck, regex = null) => {
      if (permissionCheck(item)) {
        addNavMenu(item, isActive(item.name, regex));
      }
    };

    if (hasSubmenu) {
      switch (item.name) {
        case 'Dashboards':
          handleItem(hasDashboardReadPermission, /\/([0-9]+)/);
          break;
        case 'Tasks':
          handleItem(hasTasksPermission);
          break;
        case 'Admin':
          handleItem(hasAdminPermission);
          break;
        case 'Assets':
          handleItem(hasAssetsPermission);
          break;
        default:
          addNavMenu(item, isActive(item.name));
          break;
      }
    } else {
      switch (item.name) {
        case 'Diagnostics':
        case 'Analysis Builder':
          handleItem(hasEveryResourcePermission);
          break;
        case 'Tasks':
          handleItem(hasTasksPermission);
          break;
        case 'Admin':
          handleItem(hasAdminPermission);
          break;
        default:
          addNavMenu(item, isActive(item.name));
          break;
      }
    }

    return navArray;
  };

  const renderSubNav = (item) => {
    const navArray = [];
    const hasSubmenu = subMenuHasResources(item);

    if (item.isOpen) {
      if (item.menu && hasSubmenu) {
        const subNavItem = Object.assign({}, item);
        if (subNavItem.name === 'Dashboards') {
          subNavItem.menu = dashboards.map((subNav) => (
            {
              id: subNav.udid,
              name: subNav.userDashboardName,
              url: `/${subNav.udid}`,
              resources: subNav.resources,
              type: subNav.type,
            }
          ));
        }
        if (subNavItem.name === 'Assets') {
          subNavItem.menu = subNavItem.menu.map((subNav) => ({
            ...subNav,
            show: !subNav.showIf || subNav.showIf(permissions),
          }));
        }

        if (subNavItem.menu && subNavItem.menu.length) {
          navArray.push(<NavSubmenu key={`Sub_${subNavItem.name}`} parentItem={subNavItem} toggleLeftNav={handleHideLeftNav} resources={resources} handleActiveTab={handleActiveTab} />);
        }
      }
    }

    return navArray;
  };

  const getHiddenClass = (openStatus) => {
    if (openStatus) {
      return 'mobileNavOpen';
    }
    return 'leftNavClosed';
  };

  const getHiddenClassSubNav = (data) => {
    if (data.filter((item) => item.isOpen === true).length > 0) {
      if (pinnedSubMenu) {
        return 'isSubNavOpen';
      } else {
        return 'isSubNavOpenUnpinned';
      }
    }
  };

  const handleBlur = () => {
    if (isMobile && isOpen) {
      setTimeout(() => handleHideLeftNav(), 350);
    }
  };

  const handleIn = (menuName, menuType) => {
    if (menuName === 'Home' || menuName === 'Diagnostics' || menuName === 'Analysis Builder') {
      handleMenuOut();
      return;
    }
    setLastMenuItem(menuName);
    toggleMenu(menuName, menuType);
    toggleResize();

    handleSettingSubnav();
  };

  const handleMenuOut = () => {
    setTimeout(() => {
      setHasHoveredOutMenu(true);
    }, 100);
  };

  const handleSubMenuOut = () => {
    if (!pinnedSubMenu) {
      if (lastMenuItem) {
        toggleMenu(lastMenuItem, 'out');
        setLastMenuItem(null);
      }

      toggleResize();

      setHoveringSubMenu(false);
      setHasHoveredOutMenu(false);
    }
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.paddingRight = '0';
  };

  const handlePinning = () => {
    setPinnedSubMenu(!pinnedSubMenu);
    if (pinnedSubMenu) {
      toggleMenu(lastMenuItem, 'out');
      setLastMenuItem(null);

      setHoveringSubMenu(false);
      document.documentElement.style.overflow = 'auto';
      document.documentElement.style.paddingRight = '0';
    }
    if (!pinnedSubMenu) {
      setHasHoveredOutMenu(false);
    }
    toggleResize();
  };

  const handleActiveTab = (activeTabName) => {
    setActiveTab(activeTabName);
  };

  const handleHoveringInSubmenu = () => {
    setHoveringSubMenu(true);
    handleSettingSubnav();
  };

  return (
    <>
      <div data-testid="NavContainer" role="menu" tabIndex={0} className={`nav-menu ${getHiddenClass(isOpen)}`}>
        <div data-testid="Nav Menu" className="box leftnav filter" onMouseLeave={() => handleMenuOut()} onMouseEnter={() => { setHasHoveredOutMenu(false); }}>
          <aside className="menu">
            {navData.map((item) => (
              renderNav(item)
            ))}
          </aside>
        </div>
      </div>
      <div
        data-testid='Nav Submenu'
        ref={ref}
        className={`nav-submenu ${getHiddenClassSubNav(navData)}`}
        onBlur={handleBlur}
        onMouseEnter={() => handleHoveringInSubmenu()}
        onMouseLeave={() => handleSubMenuOut()}
        style={
          {
            paddingTop: getHiddenClassSubNav(navData) === 'isSubNavOpenUnpinned' ? spaceForTop : 0,
          }
        }
      >
        <aside
          className="menu"
          height={window.innerHeight * 0.5}
          style={
            {
              top: getHiddenClassSubNav(navData) === 'isSubNavOpen' ? spaceForTop : 0,
            }
          }
        >
          {navData.map((item, key) => (<div key={key}>
            {item.isOpen && <>
              <div data-testid="NavSubmenu" key='title' className="nav-submenu-title-container">
                <div className="nav-submenu-title-area">
                  <span className="nav-submenu-title">{item.name}</span>
                  <span className="nav-submenu-pin" onClick={() => handlePinning()}>
                    <i className={pinnedSubMenu ? 'flaticon-left-chevron nav-submenu-pinned-icon' : 'flaticon-pin'}></i>
                  </span>
                </div>
                {(item.name !== 'Admin' && item.name !== 'Assets') &&
                  <div className="nav-submenu-manage-area">
                    <NavLink to={item.url} onClick={() => { setActiveTab(null); handleSubMenuOut(); }} exact className="nav-submenu-manage-link" activeClassName="is-active">
                      <span><i className={item.icon} />{`${item.name === 'Dashboards' ? ' Manage' : ''} ${item.name}`}</span>
                    </NavLink>
                  </div>
                }
              </div>
              <div
                className='nav-submenu-options-container'
                style={{ height: getHiddenClassSubNav(navData) === 'isSubNavOpenUnpinned' ? `calc(100vh - ${spaceForBottom}px)` : '100%' }}
              >
                {renderSubNav(item)}
              </div>
            </>}
          </div>),
          )
          }
        </aside>
      </div>
    </>
  );
};

Nav.propTypes = {
  dashboards: PropTypes.array.isRequired,
  navData: PropTypes.array.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleLeftNav: PropTypes.func.isRequired,
  toggleResize: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired,
};

export default Nav;
