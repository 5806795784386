import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { connect , useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { process } from '@progress/kendo-data-query';
import { GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import omit from 'lodash/omit';
import BaseGrid from '../common/Grid/BaseGrid';
import { CultureContext } from '../intl';
import deploymentsColumn from './deploymentsColumn';
import { getTableWidth, searchForMatchKeyword, getGridColumnWidthInPx } from '../../utils';
import { makeAsync } from '../common/Grid/utils';
import { createCellRenderer, exportCsv, formatExportExcelData } from './utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import withDeploymentsModuleWrapper from '../../hocs/withDeploymentsModuleWrapper';
import HeaderCell from '../common/HeaderCell';
import DeploymentsMenuPopup from './DeploymentsMenuPopup';
import { CustomColumnMenu } from '../admin/widgets/CustomColumnMenu';
import IconButton from '../common/IconButton';
import { hideModal, showModal } from '../../actions/modalActions';
import { getDeploymentGroups } from '../../actions/deploymentGroupsActions';

const tableSelector = '.deployment-grid .k-widget.k-grid';

const deploymentGridConfig = {
  sort: [{ field: 'deploymentName', dir: 'asc' }],
  take: 25,
  skip: 0,
};

const ManageDeployments = ({ selectedOrgs, availableOrgs }) => {
  const dispatch = useDispatch();
  const isDeploymentGroupsLoading= useSelector((state) => state.ajaxCallsInProgress.deploymentGroups);

  const { culture } = useContext(CultureContext);
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const columnSelectorPopupWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const exportExcelRef = useRef();

  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);

  const [gridState, setGridState] = useState(() => createDataState(deploymentGridConfig, []));
  const [deploymentList, setDeploymentList] = useState([]);
  const [filteredDeploymentList, setFilteredDeploymentList] = useState(deploymentList || []);
  const [search, setSearch] = useState('');
  const [appliedSearch, setAppliedSearch] = useState('');

  const [gridWidth, setGridWidth] = useState(768);
  useResizeObserver(document.querySelector('.main-container'), (result) => {
    if (result) setGridWidth((prevWidth) => getTableWidth(prevWidth, tableSelector));
  });

  const allData = useMemo(
    () => {
      const config = { ...gridState.dataState, skip: 0, take: undefined };
      const list = createDataState(config, filteredDeploymentList);
      return list.result.data;
    },
    [gridState, filteredDeploymentList],
  );

  const visibleColumns = useMemo(
    () => deploymentsColumn.filter((column) => column.show),
    [deploymentsColumn],
  );

  const exportColumns = useMemo(
    () => {
      const noWidthColumns = visibleColumns.map((column) => omit(column, ['width']));
      return noWidthColumns;
    },
    [visibleColumns],
  );

  const cellRender = useCallback(
    (column) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture })
      : column.cell,
    [culture],
  );

  const stringifiedGridStateData = useMemo(
    () =>
      JSON.stringify(gridState.result.data)
    ,
    [gridState.result.data, gridState.dataState.skip],
  );

  const renderedColumns = useMemo(
    () => visibleColumns.map((column, idx) => (
      <GridColumn
        key={idx}
        field={column.field}
        title={column.title}
        filter={column.filter}
        sortable={column.sortable}
        reorderable={column.reorderable !== false}
        orderIndex={column.orderIndex}
        width={getGridColumnWidthInPx(column, gridWidth)}
        headerClassName={column.headerClassName}
        className={column.className}
        render={column.render}
        cell={cellRender(column)}
        headerCell={HeaderCell}
        columnMenu={
          (props) =>
            (<CustomColumnMenu
              {...props}
              showFilter={column.filterable}
              showSort={column.sortable}
              columns={visibleColumns}
              showColumn={false}
            />)
        }
      />
    )),
    [visibleColumns, cellRender, gridWidth, stringifiedGridStateData],
  );

  // load deployments on mount
  useEffect(() => {
    if (!isDeploymentGroupsLoading) {
      fetchDeployments();
    }
  }, [selectedOrgs]);

  useEffect(() => {
    setFilteredDeploymentList(getDeploymentListWithSearch(deploymentList));
  }, [appliedSearch, deploymentList]);

  // update grid on deploymentList change
  useEffect(() => {
    setGridState(createDataState({ ...gridState.dataState, skip: 0 }, filteredDeploymentList));
  }, [filteredDeploymentList]);


  function createDataState(dataState, deploymentRecords) {
    const result = process(deploymentRecords, dataState);

    return {
      dataState,
      result,
    };
  }

  function fetchDeployments() {
    dispatch(getDeploymentGroups(selectedOrgs.length ? selectedOrgs : availableOrgs)).then((response) => setDeploymentList(response));
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, filteredDeploymentList),
    }));
  }

  function handleKeyPress(e) {
    if ((e.keyCode || e.which) === 13) {
      applySearch();
    }
  }

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getDeploymentListWithSearch(list) {
    return list.filter((item) => {
      const {
        organizationName, name, description, notes, equipmentCount, statusName,
      } = item;
      const fields = [
        organizationName, name, description, notes, equipmentCount, statusName,
      ];
      const fullString = fields.filter((field) => field !== null).join('  ');
      return searchForMatchKeyword(fullString, appliedSearch);
    });
  }

  function applySearch() {
    setAppliedSearch(search);
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Export CSV':
        handleExportCsv();
        break;
      case 'Export Excel':
        handleExportExcel();
        break;
      default:
        break;
    }
  }

  function blurTimeout() {
    showMenu && setShowMenu(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    const wrapperEl = menuWrapperRef.current;
    wrapperEl && wrapperEl.focus();

    const wrapperEl2 = columnSelectorPopupWrapperRef.current;
    wrapperEl2 && wrapperEl2.focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('id')
    ) {
      if (relatedTarget.getAttribute('id').includes('column-visibility')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-container')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-reset')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-submit')) return;
    }

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      makeAsync(() => {
        const excelColumns = exportColumns.map((column) => ({
          ...column,
          width: getGridColumnWidthInPx(column, gridWidth),
          cell: column.render
            ? createCellRenderer(column, { renderType: 'excel', culture })
            : column.cell,
        }));
        const formattedAllData = formatExportExcelData(allData, excelColumns);
        exportExcelRef.current.save(formattedAllData, excelColumns);
      });
    }
  }

  function handleExportCsv() {
    makeAsync(() => {
      const csvColumns = exportColumns.map((column) => ({
        ...column,
        cell: column.render
          ? createCellRenderer(column, { renderType: 'csv', culture })
          : column.cell,
      }));
      exportCsv(`Deployment - ${Date.now()}`, csvColumns, allData);
    });
  }

  function closeModal() {
    dispatch(hideModal());
  }

  function refreshDeploymentsAndCloseModal() {
    closeModal();
    fetchDeployments();
  }

  function handleOpenDeploymentModal(props) {
    dispatch(showModal('DEPLOYMENT_MODAL', {
      modalContent: 'full scrollable',
      cancelCallback: closeModal,
      saveCallback: refreshDeploymentsAndCloseModal,
      ...props,
    }));
  }

  function handleOpenCreateDeploymentModal() {
    handleOpenDeploymentModal({ mode: 'add' });
  }

  function handleOpenEditDeploymentModal({ dataItem }) {
    handleOpenDeploymentModal({ dataItem, mode: 'edit' });
  }

  return (
    <div data-testid="ManageDeployment" className="column content-spacing-container">
      <div className="level" style={{ marginBottom: '.5rem' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Deployments</h1>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <IconButton
              iconName="flaticon-plus"
              onClick={handleOpenCreateDeploymentModal}
              title="New Deployment"
              text="New Deployment"
              className='button'
            />
          </div>
        </div>
      </div>

      <DeploymentsMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />

      <BaseGrid
        wrapperClassName="deployment-grid"
        data={gridState.result}
        {...gridState.dataState}
        onDataStateChange={dataStateChange}
        expandField="expanded"
        selectedField="selected"
        sortable={{ allowUnsort: true }}
        resizable
        reorderable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        onRowClick={handleOpenEditDeploymentModal}
      >
        <GridToolbar>
          <div className="level-right">
            <div className="level-right">
              <div className="level-item">
                <input
                  size={32}
                  type="text"
                  className="input"
                  placeholder="Enter text to search columns"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="level-item">
                <button className="button" title="Search" onClick={applySearch}>
                  <span className="icon">
                    <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className="level-item">
                <button
                  name="btnExport"
                  className="button"
                  title="More"
                  ref={anchorMenuRef}
                  onClick={handleMenuClick}
                >
                  <span className="icon">
                    <i className="flaticon-menu" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          {isDeploymentGroupsLoading
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        {renderedColumns}
      </BaseGrid>

      <ExcelExport
        ref={exportExcelRef}
        fileName={`Deployments - ${Date.now()}.xlsx`}
      />
    </div>
  );
};

ManageDeployments.propTypes = {
  selectedOrgs: PropTypes.array,
  availableOrgs: PropTypes.array,
};

const mapStateToProps = (state) => ({
  selectedOrgs: state.user.jsonFilters.find((e) => e.key === 'client').value,
  availableOrgs: state.dashboardFilters.client,
});

export default withDeploymentsModuleWrapper(connect(mapStateToProps)(ManageDeployments));
