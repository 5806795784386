import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as apiErrorHandler from '../../api/apiErrorHandler';
import * as loginActions from '../../actions/loginActions';
import * as uiActions from '../../actions/uiActions';
import * as modalActions from '../../actions/modalActions';
import * as userActions from '../../actions/userActions';
import * as contactActions from '../../actions/contactActions';
import Header from './Header';
/* eslint-disable react/prefer-stateless-function */

class HeaderManager extends React.Component {
  constructor(props) {
    super(props);
    this.toggleRightNav = this.toggleRightNav.bind(this);
  }

  toggleRightNav() {
    this.props.actions.toggleRightNav();
  }

  showUserSettings = () => {
    const { user, actions } = this.props;

    actions.showModal(
      'USER_SETTINGS',
      {
        user,
        modalContent: 'full',
        title: 'User Settings',
        saveCallback: this.saveUserSettings,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Save',
      });
  };

  showContact = () => {
    const { actions } = this.props;

    actions.showModal(
      'CONTACT_FORM',
      {
        modalContent: 'full',
        title: 'Contact Form',
        saveCallback: this.saveContact,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Save',
      });
  };

  saveUserSettings = (user) => {
    const { actions } = this.props;
    actions.editUser(user).then(
      () => {
        const toastText = 'Units & Currency Saved';
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        actions.hideModal();
      }).catch(actions.apiErrorHandler);
  };

  saveContact = (contact) => {
    const { actions } = this.props;
    actions.addContact(contact).then(
      () => {
        const toastText = 'Email Sent';
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        actions.hideModal();
      }).catch(actions.apiErrorHandler);
  };

  render() {
    const { prodName, reportTheme, logoImage, user, units, actions, rightnav, location } = this.props;
    const pageFound = location.pathname !== '/not-found';

    return (
      <Header
        pageFound={pageFound}
        prodName={prodName}
        reportTheme={reportTheme}
        logoImage={logoImage}
        user={user}
        units={units}
        logout={actions.logout}
        location={location}
        rightnav={rightnav}
        onToggleRightNav={this.toggleRightNav}
        onSettingsClick={this.showUserSettings}
        onContactClick={this.showContact}
      />
    );
  }
}

HeaderManager.propTypes = {
  prodName: PropTypes.string.isRequired,
  reportTheme: PropTypes.number.isRequired,
  logoImage: PropTypes.string,
  user: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  rightnav: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

function MapStateToProps(state) {
  return {
    reportTheme: state.whiteLabel.reportTheme,
    logoImage: state.whiteLabel.logoImage,
    prodName: state.whiteLabel.productName ? state.whiteLabel.productName : '',
    user: state.user,
    units: state.units,
    rightnav: state.ui.rightnav,
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...loginActions,
      ...uiActions,
      ...modalActions,
      ...userActions,
      ...contactActions,
      ...apiErrorHandler,
    }, dispatch),
  };
}

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(HeaderManager));
