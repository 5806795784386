import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDiagnosticReportEquipmentBuildingVariables } from '../../../../actions/diagnosticsModuleActions';
import ExpansionPanel from '../../../common/ExpansionPanel';
import BuildingVariablesGrid from './BuildingVariablesGrid';

const BuildingVariables = ({ unitid, bid, eid, readOnly }) => {
  const dispatch = useDispatch();
  const buildingVariables = useSelector((state) => state.diagnosticsModule.equipmentProfile.buildingVariables);
  const buildingVariablesPermission = useSelector((state) => state.permissions.buildingVariables);
  const buildingVariablesValueLoading = useSelector((state) => state.diagnosticsModule.equipmentProfile.buildingVariablesValueLoading);

  const canCreate = buildingVariablesPermission.u && !readOnly;
  const canEdit = buildingVariablesPermission.u && !readOnly;
  const canDelete = buildingVariablesPermission.u;
  const addNewRef = useRef(null);

  useEffect(() => {
    dispatch(getDiagnosticReportEquipmentBuildingVariables({ bid, eid }));
  }, []);

  const handleAddNew = () => {
    if (addNewRef.current) {
      addNewRef.current();
    }
  };

  return (
    <ExpansionPanel
      title={`Building Variables (${buildingVariables.length})`}
      loading={buildingVariablesValueLoading}
      onAddNew={canCreate ? handleAddNew : undefined}
    >
      <BuildingVariablesGrid
        unitid={unitid}
        bid={bid}
        eid={eid}
        canEdit={canEdit}
        canDelete={canDelete}
        addNewRef={addNewRef}
        data={buildingVariables}
        loading={buildingVariablesValueLoading}
      />
    </ExpansionPanel>
  );
};

BuildingVariables.propTypes = {
  readOnly: PropTypes.bool,
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default BuildingVariables;
