import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getTaskModuleQueryString } from '../actionHelpers/taskModuleHelpers';

// TASK CONFIG

export function updateTaskModuleConfigColumns(payload) {
  return { type: types.UPDATE_TASK_MODULE_CONFIG_COLUMNS, payload };
}

// GET TASK LIST
export function setTaskModuleLoading(payload) {
  return { type: types.SET_TASK_MODULE_LOADING, payload };
}

export function getTaskModuleSuccess(payload) {
  return { type: types.GET_TASK_MODULE_SUCCESS, payload };
}

export function clearTaskModuleList() {
  return { type: types.CLEAR_TASK_MODULE_LIST };
}

export function updateTaskModuleGridConfig(payload) {
  return { type: types.UPDATE_TASK_MODULE_GRID_CONFIG, payload };
}

export function getTaskModule(payload) {
  return (dispatch) => getTaskModuleQueryString(payload)
    .then((qs) => api.getTaskModule(qs))
    .then((data) => {
      dispatch(setTaskModuleLoading(true));
      dispatch(getTaskModuleSuccess(data));
      return data;
    })
    .catch((error) => {
      dispatch(setTaskModuleLoading(false));
      handleErrorResponses(error);
      return Promise.reject(error);
    });
}

//GET TASK REPORTERS AN ASSIGNEES

export function setReportersAndAssigneesLoading(payload) {
  return { type: types.SET_REPORTERS_AND_ASSIGNEES_LOADING, payload };
}

export function getReportersAndAssigneesSuccess(payload) {
  return { type: types.GET_REPORTERS_AND_ASSIGNEES_SUCCESS, payload };
}

export function clearReportersAndAssignees() {
  return { type: types.CLEAR_REPORTERS_AND_ASSIGNEES };
}

export function getReportersAndAssignees(unitID, bID) {
  return (dispatch) => {
    dispatch(setReportersAndAssigneesLoading(true));
    return api.getTaskReportersAndAssignees(unitID, bID)
      .then((data) => {
        dispatch(getReportersAndAssigneesSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskDetailsLoading(false));
      });
  };
}
// GET TASK DETAILS
export function setTaskDetailsLoading(payload) {
  return { type: types.SET_TASK_DETAILS_LOADING, payload };
}

export function getTaskDetailsSuccess(payload) {
  return { type: types.GET_TASK_DETAILS_SUCCESS, payload };
}

export function clearTaskDetails() {
  return { type: types.CLEAR_TASK_DETAILS };
}

export function getTaskDetails(id) {
  return (dispatch) => {
    dispatch(setTaskDetailsLoading(true));
    return api.getTaskDetails(id)
      .then((data) => {
        dispatch(getTaskDetailsSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskDetailsLoading(false));
      });
  };
}

// Permissions
export function setTaskPermissionsLoading(payload) {
  return { type: types.SET_TASK_PERMISSIONS_LOADING, payload };
}

export function getTaskPermissionsSuccess(payload) {
  return { type: types.GET_TASK_PERMISSIONS_SUCCESS, payload };
}


export function getTaskPermissions(unitId) {
  return (dispatch) => {
    dispatch(setTaskPermissionsLoading(true));
    return api.getTaskPermissions(unitId)
      .then((data) => {
        dispatch(getTaskPermissionsSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskPermissionsLoading(false));
      });
  };
}

// Edit task details
export function setCreateTaskSaving(payload) {
  return { type: types.SET_CREATE_TASK_SAVING, payload };
}

export function createTaskSuccess(payload) {
  return { type: types.CREATE_TASK_SUCCESS, payload };
}

export function createTask(payload) {
  return (dispatch) => {
    dispatch(setCreateTaskSaving(true));
    return api.createTask(payload)
      .then((data) => {
        dispatch(createTaskSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setCreateTaskSaving(false));
      });
  };
}

// Edit task details
export function setEditTaskDetailsSaving(payload) {
  return { type: types.SET_EDIT_TASK_DETAILS_SAVING, payload };
}

export function editTaskDetailsSuccess(payload) {
  return { type: types.EDIT_TASK_DETAILS_SUCCESS, payload };
}

export function editTaskDetails(bid, id, payload) {
  return (dispatch) => {
    dispatch(setEditTaskDetailsSaving(true));
    return api.editTaskDetails(bid, id, payload)
      .then((data) => {
        dispatch(editTaskDetailsSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setEditTaskDetailsSaving(false));
      });
  };
}

// Delete task
export function setTaskModuleDeleting(payload) {
  return { type: types.SET_TASK_MODULE_DELETING, payload };
}

export function deleteTaskModuleSuccess(payload) {
  return { type: types.DELETE_TASK_MODULE_SUCCESS, payload };
}

export function deleteTaskModule({ id, bid }) {
  return (dispatch) => {
    dispatch(setTaskModuleDeleting(true));
    return api.deleteTaskModule({ id, bid })
      .then((data) => {
        dispatch(deleteTaskModuleSuccess(id));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskModuleDeleting(false));
      });
  };
}


// Bulk Delete task
export function setTaskModuleBulkDeleting(payload) {
  return { type: types.SET_TASK_MODULE_BULK_DELETING, payload };
}

export function bulkDeleteTaskModuleSuccess(payload) {
  return { type: types.BULK_DELETE_TASK_MODULE_SUCCESS, payload };
}

export function bulkDeleteTaskModule({ ids }) {
  return (dispatch) => {
    dispatch(setTaskModuleBulkDeleting(true));
    return api.bulkDeleteTaskModule({ taskID: ids })
      .then((data) => {
        dispatch(bulkDeleteTaskModuleSuccess(ids));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskModuleBulkDeleting(false));
      });
  };
}

// Bulk Edit task
export function setTaskModuleBulkEditing(payload) {
  return { type: types.SET_TASK_MODULE_BULK_EDITING, payload };
}

export function bulkEditTaskModuleSuccess(payload) {
  return { type: types.BULK_EDIT_TASK_MODULE_SUCCESS, payload };
}

export function bulkEditTaskModule(payload) {
  return (dispatch) => {
    dispatch(setTaskModuleBulkEditing(true));
    return api.bulkEditTaskModule(payload)
      .then((data) => {
        dispatch(bulkEditTaskModuleSuccess(data.data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskModuleBulkEditing(false));
      });
  };
}


// Update Task Module Config
export function setTaskModuleConfigSaving(payload) {
  return { type: types.SET_TASK_MODULE_CONFIG_SAVING, payload };
}

export function updateTaskModuleConfigSuccess(payload) {
  return { type: types.UPDATE_TASK_MODULE_CONFIG_SUCCESS, payload };
}

export function updateTaskModuleConfig(config) {
  return (dispatch) => {
    dispatch(setTaskModuleConfigSaving(true));
    return api.updateTaskModuleConfig(config)
      .then((data) => {
        dispatch(updateTaskModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setTaskModuleConfigSaving(false));
      });
  };
}

export function resetTaskModuleJsonFilters() {
  return { type: types.RESET_TASK_MODULE_JSON_FILTERS };
}

export function getTaskPermissionsByUnitIdSuccess(payload) {
  return { type: types.GET_TASK_PERMISSIONS_BY_UNIT_ID_SUCCESS, payload };
}

export function getTaskPermissionsByUnitId(unitId) {
  return (dispatch) => api.getTaskPermissions(unitId)
    .then((data) => {
      dispatch(getTaskPermissionsByUnitIdSuccess({ orgId: unitId, permissions: data }));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}
