import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { diagnosticSettings, diagnosticTableSettings, diagnosticTotalSettings } from './index';
import { formatCurrency, toQueryString } from '../../../../utils';
import { topFilterDefaultValues } from '../../../diagnostics/TopFilter';
import currencyOptions from '../../../../enums/currency';
import PageLoading from '../../../common/PageLoading';

const DiagnosticTile = () => {
  const { config, datasetResult } = useTileManager(diagnosticSettings);
  const { config: configForTable, datasetResult: datasetResultForTable, queryParams, fetching } = useTileManager(diagnosticTableSettings);
  const { datasetResult: datasetResultForTotal, isoCurrencySymbol, culture } = useTileManager(diagnosticTotalSettings);
  const { totalAvoidable, costLabel } = datasetResultForTotal[0] || { totalAvoidable: 0, costLabel: isoCurrencySymbol };

  const hasResult = !!datasetResultForTable.length;

  const datasetResultForTableFormatted = useMemo(() => datasetResultForTable.map((item) => {
    const sumCostFormatted = formatCurrency(
      item.sumCost,
      item.convertedAvoidableCostUnitLabel,
      culture,
      currencyOptions.wholeNumberSymbol,
    );
    return {
      ...item,
      sumCostFormatted,
    };
  }), [datasetResultForTable, culture]);

  const queryString = useMemo(() => {
    const queryObject = {
      unitid: queryParams.UnitID,
      bid: queryParams.BID,
      eid: queryParams.EID,
      aid: queryParams.AID,
      rng: queryParams.DiagnosticAnalysisInterval,
      sd: queryParams.DiagnosticStartDate,
      ed: queryParams.DiagnosticEndDate,
      topValue: 100,
      topBy: topFilterDefaultValues.topBy,
    };
    return toQueryString(queryObject);
  }, [queryParams]);

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;
  
  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-data-analysis-1' aria-hidden="true" />
        <p className="value">{formatCurrency(totalAvoidable, costLabel, culture, currencyOptions.wholeNumberSymbol)}</p>
        <p className="label">energy waste</p>
      </div>

      <div className="widget-renderer-wrapper">
        <div className="widget-renderer" style={{ height: 250 }}>
          <WidgetRenderer
            key={datasetResult}
            wtid={-1}
            config={config}
            datasetResult={datasetResult}
            forceUpdateConfig={JSON.stringify(config)}
            id={diagnosticSettings.id}
            type={diagnosticSettings.type}
            crossFilter={diagnosticSettings.crossFilter}
            datasetIDs={diagnosticSettings.datasetIDs}
          />
        </div>

        <div className="table-label-wrapper">
          <p className="table-label">
            Top Energy Issues
            <Link title='Go to diagnostics' to={`/diagnostics?${queryString}`} className={hasResult ? '' : 'disabled'}>
              <i className='icon flaticon-chevron' aria-hidden="true" />
            </Link>
          </p>
        </div>

        <div className="widget-renderer table">
          <WidgetRenderer
            key={datasetResultForTableFormatted}
            wtid={-1}
            config={configForTable}
            datasetResult={datasetResultForTableFormatted}
            forceUpdateConfig={JSON.stringify(configForTable)}
            id={diagnosticTableSettings.id}
            type={diagnosticTableSettings.type}
            crossFilter={diagnosticTableSettings.crossFilter}
            datasetIDs={diagnosticTableSettings.datasetIDs}
          />
        </div>
      </div>
    </>
  );
};

export default DiagnosticTile;
