import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function permissionsReducer(state = initialState.permissions, action) {
  switch (action.type) {
    case types.GET_DASHBOARD_TEMPLATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        dashboardTemplates: action.payload,
      };

    case types.GET_ORG_TEMPLATES_PERMISSION_SUCCESS:
      return {
        ...state,
        orgDashboardTemplates: action.payload,
      };

    case types.GET_USER_ORG_TEMPLATES_PERMISSION_SUCCESS:
      return {
        ...state,
        userOrgDashboardTemplates: action.payload,
      };

    case types.GET_DASHBOARD_PERMISSION_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
      };

    case types.GET_BUILDING_PERMISSION_SUCCESS:
      return {
        ...state,
        building: action.payload,
      };

    case types.GET_EQUIPMENT_PERMISSION_SUCCESS:
      return {
        ...state,
        equipment: action.payload,
      };

    case types.GET_EQUIPMENT_VARIABLES_PERMISSION_SUCCESS:
      return {
        ...state,
        equipmentVariables: action.payload,
      };

    case types.GET_BUILDING_VARIABLES_PERMISSION_SUCCESS:
      return {
        ...state,
        buildingVariables: action.payload,
      };

    case types.GET_ORG_PERMISSION_SUCCESS:
      return {
        ...state,
        org: action.payload,
      };

    case types.GET_POINT_PERMISSION_SUCCESS:
      return {
        ...state,
        point: action.payload,
      };

    case types.GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS:
      return {
        ...state,
        equipmentVariablesByUnitId: {
          ...state.equipmentVariablesByUnitId,
          [action.payload.unitId]: action.payload.permissions,
        },
      };

    case types.GET_SCHEDULED_ANALYSES_PERMISSIONS_BY_UNIT_ID_SUCCESS:
      return {
        ...state,
        scheduledAnalysesByUnitId: {
          ...state.scheduledAnalysesByUnitId,
          [action.payload.unitId]: action.payload.permissions,
        },
      };

    case types.GET_EQUIPMENT_PERMISSION_BY_UNIT_ID_SUCCESS:
      return {
        ...state,
        equipmentByUnitId: {
          ...state.equipmentByUnitId,
          [action.payload.unitId]: action.payload.permissions,
        },
      };

    default:
      return state;
  }
}
