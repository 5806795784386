import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFilterField, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { filterFields, filterIds } from '../enums/filters';
import PageLoading from '../components/common/PageLoading';

const withPointsModuleWrapper = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  const jsonFilters = useSelector((state) => state.pointModule.config.jsonFilters);
  const jsonFiltersFromAPILoaded = useSelector((state) => state.pointModule.config.jsonFiltersFromAPILoaded);
  const pointModuleJsonFilters = jsonFilters;

  useEffect(() => {
    document.title = 'Assets | Points';
    dispatch(setExcludePageFilterIds([
      filterIds.diagnosticDate,     
      filterIds.diagnosticAnalysisInterval,     
      filterIds.analysis,     
      filterIds.diagnosticDate,     
      filterIds.diagnosticDate,     
      filterIds.diagnosticDate,     
      filterIds.energy,
      filterIds.comfort,
      filterIds.maintenance,
      filterIds.avoidableCost,
      
      filterIds.taskOpenedDate,
      filterIds.taskCompletionDate,
      filterIds.taskModifiedDate,
      filterIds.taskStatus,
      filterIds.taskAssignee,
      filterIds.taskReporter,
      filterIds.annualAvoidableCost,

      filterIds.vPoint,
      filterIds.rawdatasetDate,

      filterIds.currency,
    ]));
  }, []);

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.pointModule));
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass="page-loading-container" />;
  }

  return mounted && (
    <WrappedComponent
      {...props}
      pointModuleJsonFilters={pointModuleJsonFilters}
    />
  );
};

export default withPointsModuleWrapper;
