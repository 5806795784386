import sortBy from 'lodash/sortBy';
import * as api from '../api/api';
import * as types from './actionTypes';
import { push } from 'connected-react-router';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getDiagnosticsModuleQueryString } from '../actionHelpers/diagnosticHelpers';
import { beginDiagnosticDetailsCall, diagnosticDetailsCallError, initializeDiagnosticDetails,
  updateReprocessDiagnosticsCall, updateReprocessDiagnosticsError, beginUpdateEquipmentProfileCall,
  updateEquipmentProfileError, beginUpdateEquipmentNotesCall, updateEquipmentNotesError, 
  updateConfigurationNotesError,
  beginUpdateConfigurationNotesCall} from './ajaxStatusActions';

// DIAGNOSTICS CONFIG

export function updateDiagnosticsModuleConfigColumns(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_COLUMNS, payload };
}

export function updateDiagnosticsModuleConfigTopFilter(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_TOP_FILTER, payload };
}

// GET DIAGNOSTICS LIST
export function setDiagnosticsModuleLoading(payload) {
  return { type: types.SET_DIAGNOSTICS_MODULE_LOADING, payload };
}

export function getDiagnosticsModuleSuccess(payload) {
  return { type: types.GET_DIAGNOSTICS_MODULE_SUCCESS, payload };
}

export function clearDiagnosticsModule(payload) {
  return { type: types.CLEAR_DIAGNOSTICS_MODULE, payload };
}

export function updateDiagnosticsModuleGridConfig(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_MODULE_GRID_CONFIG, payload };
}

function updateReprocessDiagnosticsSucess(payload) {
  return { type: types.UPDATE_REPROCESS_DIAGNOSTICS_SUCCESS, payload };
}

export const reprocessDiagnostics = (payload) => (dispatch) => {
  dispatch(updateReprocessDiagnosticsCall());
  return api.reprocessDiagnostics(payload)
    .then((response) => dispatch(updateReprocessDiagnosticsSucess(response)))
    .catch((error) => {
      dispatch(updateReprocessDiagnosticsError(error));
      handleErrorResponses(error);
    });
};

export function getDiagnosticsModule(payload, noStateUpdate) {
  return (dispatch) => getDiagnosticsModuleQueryString(payload)
    .then((qs) => api.getDiagnosticsModule(qs))
    .then((data) => {
      if (noStateUpdate) return data;
      dispatch(setDiagnosticsModuleLoading(true));
      dispatch(getDiagnosticsModuleSuccess(data));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      return Promise.reject(error);
    }).finally(() => {
      dispatch(setDiagnosticsModuleLoading(false));
    });
}

// Update Diagnostics Module Config
export function setDiagnosticsModuleConfigSaving(payload) {
  return { type: types.SET_DIAGNOSTICS_MODULE_CONFIG_SAVING, payload };
}

export function updateDiagnosticsModuleConfigSuccess(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_SUCCESS, payload };
}

export function updateDiagnosticsModuleConfig(config) {
  return (dispatch) => {
    dispatch(setDiagnosticsModuleConfigSaving(true));
    return api.updateDiagnosticsModuleConfig(config)
      .then((data) => {
        dispatch(updateDiagnosticsModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setDiagnosticsModuleConfigSaving(false));
      });
  };
}

export function resetDiagnosticsModuleJsonFilters() {
  return { type: types.RESET_DIAGNOSTICS_MODULE_JSON_FILTERS };
}

export function getDiagnosticDetailsSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_DETAILS_SUCCESS, payload };
}

export function setInitializeDiagnosticDetail() {
  return (dispatch) => {
    dispatch(initializeDiagnosticDetails());
  };
}

export function getDiagnosticDetails(params) {
  return (dispatch) => {
    dispatch(beginDiagnosticDetailsCall());
    return api.getDiagnosticDetails(params).then((diagnostic) => {
      dispatch(getDiagnosticDetailsSuccess(diagnostic));
    }).catch((error) => {
      dispatch(diagnosticDetailsCallError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
          afterYesCallback: () => dispatch(push('/diagnostics')),
        },
      ));
    });
  };
}

export function clearDiagnosticEquipmentProfile() {
  return { type: types.CLEAR_DIAGNOSTIC_EQUIPMENT_PROFILE };
}

export function getDiagnosticReportEquipmentSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_SUCCESS, payload };
}

export function getDiagnosticReportEquipment(params) {
  return (dispatch) => api.getDiagnosticReportEquipment(params)
    .then((response) => {
      dispatch(getDiagnosticReportEquipmentSuccess(response));
      return response;
    }).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
    });
}

export function getDiagnosticReportEquipmentVariablesSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_VARIABLES_SUCCESS, payload };
}

export function getDiagnosticReportEquipmentVariables(params) {
  return (dispatch) => api.getDiagnosticReportEquipmentVariables(params).then((response) => {
    dispatch(getDiagnosticReportEquipmentVariablesSuccess(response));
    return response;
  }).catch((error) => {
    handleErrorResponses(error);
    dispatch(apiErrorHandler(error));
  });
}

export function getDiagnosticReportEquipmentPointsSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_POINTS_SUCCESS, payload };
}

export function getDiagnosticReportEquipmentPoints(params) {
  return (dispatch) => api.getDiagnosticReportEquipmentPoints(params).then((response) => {
    dispatch(getDiagnosticReportEquipmentPointsSuccess(response));
    return response;
  }).catch((error) => {
    handleErrorResponses(error);
    dispatch(apiErrorHandler(error));
  });
}

export function getDiagnosticReportEquipmentBuildingVariablesSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_BUILDING_VARIABLES_SUCCESS, payload };
}

export function getDiagnosticReportEquipmentBuildingVariables(params) {
  return (dispatch) => api.getDiagnosticReportEquipmentBuildingVariables(params)
    .then((response) => {
      dispatch(getDiagnosticReportEquipmentBuildingVariablesSuccess(response));
      return response;
    }).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
    });
}

export function getDiagnosticReportAssociatedEquipmentSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_SUCCESS, payload };
}

export function getDiagnosticReportAssociatedEquipment(params) {
  return (dispatch) => api.getDiagnosticReportAssociatedEquipment(params).then((response) => {
    dispatch(getDiagnosticReportAssociatedEquipmentSuccess(response));
  }).catch((error) => {
    handleErrorResponses(error);
    dispatch(apiErrorHandler(error));
  });
}

export function getDiagnosticReportAssociatedEquipmentVariablesSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_VARIABLES_SUCCESS, payload };
}

export function getDiagnosticReportAssociatedEquipmentVariables(params) {
  return (dispatch) => api.getDiagnosticReportAssociatedEquipmentVariables(params).then((response) => {
    dispatch(getDiagnosticReportAssociatedEquipmentVariablesSuccess(response));
  }).catch((error) => {
    handleErrorResponses(error);
    dispatch(apiErrorHandler(error));
  });
}

export function getDiagnosticReportAssociatedEquipmentPointsSuccess(payload) {
  return { type: types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_POINTS_SUCCESS, payload };
}

export function getDiagnosticReportAssociatedEquipmentPoints(params) {
  return (dispatch) => api.getDiagnosticReportAssociatedEquipmentPoints(params).then((response) => {
    dispatch(getDiagnosticReportAssociatedEquipmentPointsSuccess(response));
  }).catch((error) => {
    handleErrorResponses(error);
    dispatch(apiErrorHandler(error));
  });
}

// Get EquipmentVariables permissions
function getEquipmentVariablesPermissionSuccess(payload) {
  return { type: types.GET_EQUIPMENT_VARIABLES_PERMISSION_SUCCESS, payload };
}
function getEquipmentVariablesPermissionError(error) {
  return { type: types.GET_EQUIPMENT_VARIABLES_PERMISSION_ERROR, error };
}
export function getEquipmentVariablesPermission() {
  return (dispatch) => api.getEquipmentVariablesPermission()
    .then((response) => {
      dispatch(getEquipmentVariablesPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getEquipmentVariablesPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get EquipmentVariables permissions by Unit ID
function getEquipmentVariablesPermissionByUnitIdSuccess(payload) {
  return { type: types.GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_SUCCESS, payload };
}
function getEquipmentVariablesPermissionByUnitIdError(error) {
  return { type: types.GET_EQUIPMENT_VARIABLES_PERMISSION_BY_UNIT_ID_ERROR, error };
}
export function getEquipmentVariablesPermissionByUnitId(unitId) {
  return (dispatch) => api.getEquipmentVariablesPermission(unitId)
    .then((response) => {
      dispatch(getEquipmentVariablesPermissionByUnitIdSuccess({ unitId, permissions: response }));
      return response;
    }).catch((error) => {
      dispatch(getEquipmentVariablesPermissionByUnitIdError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get EquipmentVariables List
function getEquipmentVariablesListLoading(payload) {
  return { type: types.GET_EQUIPMENT_VARIABLES_LIST_LOADING, payload };
}
function getEquipmentVariablesListSuccess(payload) {
  return { type: types.GET_EQUIPMENT_VARIABLES_LIST_SUCCESS, payload };
}
function getEquipmentVariablesListError(error) {
  return { type: types.GET_EQUIPMENT_VARIABLES_LIST_ERROR, error };
}
export function getEquipmentVariablesList(params) {
  return (dispatch) => {
    dispatch(getEquipmentVariablesListLoading(true));
    return api.getEquipmentVariablesList(params)
      .then((response) => {
        const sortedResponse = sortBy(response, 'equipmentVariableDisplayName');
        dispatch(getEquipmentVariablesListSuccess(sortedResponse));
        return sortedResponse;
      }).catch((error) => {
        dispatch(getEquipmentVariablesListError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(getEquipmentVariablesListLoading(false));
      });
  };
}

// Create EquipmentVariables value
function createEquipmentVariablesValueLoading(payload) {
  return { type: types.CREATE_EQUIPMENT_VARIABLES_VALUE_LOADING, payload };
}
function createEquipmentVariablesValueSuccess(payload) {
  return { type: types.CREATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS, payload };
}
function createEquipmentVariablesValueError(error) {
  return { type: types.CREATE_EQUIPMENT_VARIABLES_VALUE_ERROR, error };
}
export function createEquipmentVariablesValue(data) {
  const payload = {
    'UnitID': [data.unitid],
    'BID': [data.bid],
    'EID': [data.eid],
    'LCID': data.lcid,
    'Resource': {
      'EVID': data.evid,
      'Value': data.value,
    },
  };
  return (dispatch) => api.createEquipmentVariablesValue(payload)
    .then((response) => {
      dispatch(createEquipmentVariablesValueLoading(true));
      dispatch(createEquipmentVariablesValueSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(createEquipmentVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(createEquipmentVariablesValueLoading(false));
    });
}

// Update EquipmentVariables value
function updateEquipmentVariablesValueLoading(payload) {
  return { type: types.UPDATE_EQUIPMENT_VARIABLES_VALUE_LOADING, payload };
}
function updateEquipmentVariablesValueSuccess(payload) {
  return { type: types.UPDATE_EQUIPMENT_VARIABLES_VALUE_SUCCESS, payload };
}
function updateEquipmentVariablesValueError(error) {
  return { type: types.UPDATE_EQUIPMENT_VARIABLES_VALUE_ERROR, error };
}
export function updateEquipmentVariablesValue(data) {
  const payload = {
    'UnitID': [data.unitid],
    'BID': [data.bid],
    'EID': [data.eid],
    'LCID': data.lcid,
    'Resource': {
      'update': {
        'EVID': data.evid,
        'Value': data.value,
      },
    },
  };
  return (dispatch) => api.updateEquipmentVariablesValue(payload)
    .then((response) => {
      dispatch(updateEquipmentVariablesValueLoading(true));
      dispatch(updateEquipmentVariablesValueSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(updateEquipmentVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(updateEquipmentVariablesValueLoading(false));
    });
}

// Delete EquipmentVariables value
function deleteEquipmentVariablesValueLoading(payload) {
  return { type: types.DELETE_EQUIPMENT_VARIABLES_VALUE_LOADING, payload };
}
function deleteEquipmentVariablesValueSuccess(payload) {
  return { type: types.DELETE_EQUIPMENT_VARIABLES_VALUE_SUCCESS, payload };
}
function deleteEquipmentVariablesValueError(error) {
  return { type: types.DELETE_EQUIPMENT_VARIABLES_VALUE_ERROR, error };
}
export function deleteEquipmentVariablesValue(data) {
  const payload = {
    'UnitID': [data.unitid],
    'BID': [data.bid],
    'EID': [data.eid],
    'LCID': data.lcid,
    'resource': {
      'EVID': data.evid,
    },
  };
  return (dispatch) => api.deleteEquipmentVariablesValue(payload)
    .then((response) => {
      dispatch(deleteEquipmentVariablesValueLoading(true));
      dispatch(deleteEquipmentVariablesValueSuccess(data));
      return response;
    }).catch((error) => {
      dispatch(deleteEquipmentVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(deleteEquipmentVariablesValueLoading(false));
    });
}

// Get BuildingVariables permissions
function getBuildingVariablesPermissionSuccess(payload) {
  return { type: types.GET_BUILDING_VARIABLES_PERMISSION_SUCCESS, payload };
}
function getBuildingVariablesPermissionError(error) {
  return { type: types.GET_BUILDING_VARIABLES_PERMISSION_ERROR, error };
}
export function getBuildingVariablesPermission() {
  return (dispatch) => api.getBuildingVariablesPermission()
    .then((response) => {
      dispatch(getBuildingVariablesPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getBuildingVariablesPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get BuildingVariables List
function getBuildingVariablesListLoading(payload) {
  return { type: types.GET_BUILDING_VARIABLES_LIST_LOADING, payload };
}
function getBuildingVariablesListSuccess(payload) {
  return { type: types.GET_BUILDING_VARIABLES_LIST_SUCCESS, payload };
}
function getBuildingVariablesListError(error) {
  return { type: types.GET_BUILDING_VARIABLES_LIST_ERROR, error };
}
export function getBuildingVariablesList(params) {
  return (dispatch) => {
    dispatch(getBuildingVariablesListLoading(true));
    return api.getBuildingVariablesList(params)
      .then((response) => {
        dispatch(getBuildingVariablesListSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(getBuildingVariablesListError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(getBuildingVariablesListLoading(false));
      });
  };
}

// Create BuildingVariables value
function createBuildingVariablesValueLoading(payload) {
  return { type: types.CREATE_BUILDING_VARIABLES_VALUE_LOADING, payload };
}
function createBuildingVariablesValueSuccess(payload) {
  return { type: types.CREATE_BUILDING_VARIABLES_VALUE_SUCCESS, payload };
}
function createBuildingVariablesValueError(error) {
  return { type: types.CREATE_BUILDING_VARIABLES_VALUE_ERROR, error };
}
export function createBuildingVariablesValue(data) {
  const payload = {
    'LCID': data.lcid,
    'Resource': {
      'BID': data.bid,
      'BVID': data.bvid,
      'Value': data.value,
    },
  };
  return (dispatch) => api.createBuildingVariablesValue(payload)
    .then((response) => {
      dispatch(createBuildingVariablesValueLoading(true));
      dispatch(createBuildingVariablesValueSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(createBuildingVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(createBuildingVariablesValueLoading(false));
    });
}

// Update BuildingVariables value
function updateBuildingVariablesValueLoading(payload) {
  return { type: types.UPDATE_BUILDING_VARIABLES_VALUE_LOADING, payload };
}
function updateBuildingVariablesValueSuccess(payload) {
  return { type: types.UPDATE_BUILDING_VARIABLES_VALUE_SUCCESS, payload };
}
function updateBuildingVariablesValueError(error) {
  return { type: types.UPDATE_BUILDING_VARIABLES_VALUE_ERROR, error };
}
export function updateBuildingVariablesValue(data) {
  const payload = {
    'LCID': data.lcid,
    'Resource': {
      'BID': data.bid,
      'BVID': data.bvid,
      'update': {
        'Value': data.value,
      },
    },
  };
  return (dispatch) => api.updateBuildingVariablesValue(payload)
    .then((response) => {
      dispatch(updateBuildingVariablesValueLoading(true));
      dispatch(updateBuildingVariablesValueSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(updateBuildingVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(updateBuildingVariablesValueLoading(false));
    });
}

// Delete BuildingVariables value
function deleteBuildingVariablesValueLoading(payload) {
  return { type: types.DELETE_BUILDING_VARIABLES_VALUE_LOADING, payload };
}
function deleteBuildingVariablesValueSuccess(payload) {
  return { type: types.DELETE_BUILDING_VARIABLES_VALUE_SUCCESS, payload };
}
function deleteBuildingVariablesValueError(error) {
  return { type: types.DELETE_BUILDING_VARIABLES_VALUE_ERROR, error };
}
export function deleteBuildingVariablesValue(data) {
  const payload = {
    'LCID': data.lcid,
    'Resource': {
      'BID': data.bid,
      'BVID': data.bvid,
    },
  };
  return (dispatch) => api.deleteBuildingVariablesValue(payload)
    .then((response) => {
      dispatch(deleteBuildingVariablesValueLoading(true));
      dispatch(deleteBuildingVariablesValueSuccess(data));
      return response;
    }).catch((error) => {
      dispatch(deleteBuildingVariablesValueError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    }).finally(() => {
      dispatch(deleteBuildingVariablesValueLoading(false));
    });
}

// Update Equipment Notes
function updateEquipmentNotesSuccess(payload) {
  return { type: types.UPDATE_EQUIPMENT_NOTES_SUCCESS, payload };
}

export function updateEquipmentNotes(payload) {
  return (dispatch) => {
    dispatch(beginUpdateEquipmentNotesCall());
    return api.updateEquipmentProfile(payload)
      .then((response) => {
        dispatch(updateEquipmentNotesSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(updateEquipmentNotesError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      });
  };
}

// Update Configuration Notes
function updateConfigurationNotesSuccess(payload) {
  return { type: types.UPDATE_CONFIGURATION_NOTES_SUCCESS, payload };
}

export function updateConfigurationNotes(payload) {
  return (dispatch) => {
    dispatch(beginUpdateConfigurationNotesCall());
    return api.updateEquipmentProfile(payload)
      .then((response) => {
        dispatch(updateConfigurationNotesSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(updateConfigurationNotesError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      });
  };
}

// Update EquipmentProfile value
function updateEquipmentProfileSuccess(payload) {
  return { type: types.UPDATE_EQUIPMENT_PROFILE_SUCCESS, payload };
}

export function updateEquipmentProfile(payload) {
  return (dispatch) => {
    dispatch(beginUpdateEquipmentProfileCall());
    return api.updateEquipmentProfile(payload)
      .then((response) => {
        dispatch(updateEquipmentProfileSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(updateEquipmentProfileError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      });
  };
}

// Get Org Permissions
function getOrgPermissionSuccess(payload) {
  return { type: types.GET_ORG_PERMISSION_SUCCESS, payload };
}
function getOrgPermissionError(error) {
  return { type: types.GET_ORG_PERMISSION_ERROR, error };
}
export function getOrgPermission() {
  return (dispatch) => api.getOrgPermission()
    .then((response) => {
      dispatch(getOrgPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getOrgPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Update Point
function updatePointLoading(payload) {
  return { type: types.UPDATE_POINT_LOADING, payload };
}
function updatePointSuccess(payload) {
  return { type: types.UPDATE_POINT_SUCCESS, payload };
}
function updatePointError(error) {
  return { type: types.UPDATE_POINT_ERROR, error };
}
export function updatePoint(data) {
  const payload = {
    'PID': [data.pid],
    'Resource': {
      'Update': {
        'PointName': data.pointName,
        'PointTypeID': data.pointTypeId,
      },
    },
  };
  return (dispatch) => {
    dispatch(updatePointLoading(true));
    return api.updatePoint(payload)
      .then((response) => {
        dispatch(updatePointSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(updatePointError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(updatePointLoading(false));
      });
  };
}

// Get Point Type View
function getPointTypeViewLoading(payload) {
  return { type: types.GET_POINT_TYPE_VIEW_LOADING, payload };
}
function getPointTypeViewSuccess(payload) {
  return { type: types.GET_POINT_TYPE_VIEW_SUCCESS, payload };
}
function getPointTypeViewError(error) {
  return { type: types.GET_POINT_TYPE_VIEW_ERROR, error };
}
export function getPointTypeView(params) {
  return (dispatch) => {
    dispatch(getPointTypeViewLoading(true));
    return api.getPointTypeView(params)
      .then((response) => {
        dispatch(getPointTypeViewSuccess(response));
        return response;
      }).catch((error) => {
        dispatch(getPointTypeViewError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(getPointTypeViewLoading(false));
      });
  };
}

// Get Equipment Variables by Equipment Class ID
export function getEquipmentVariablesListByEquipmentClassId(params) {
  return (dispatch) => api.getEquipmentVariablesListByEquipmentClassId(params)
    .then((response) => response).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Equipment Variables by Equipment Class ID
export function getEquipmentVariablesPreviewList(params) {
  return (dispatch) => api.getEquipmentVariablesPreviewList(params)
    .then((response) => response).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Bulk Update / Assign Equipment Variables
export function bulkUpdateEquipmentVariables(params) {
  return (dispatch) => api.bulkUpdateEquipmentVariables(params)
    .then((response) => response).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    });
}

// Bulk Delete / Unassign Equipment Variables
export function bulkDeleteEquipmentVariables(params) {
  return (dispatch) => api.bulkDeleteEquipmentVariables(params)
    .then((response) => response).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
      return Promise.reject(error);
    });
}
