/**
 *  * Created by Stewart Gordon on 6/4/2018.
 */
import standardDefaultFormats from '../intl/standardDefaultFormats';
import { formatDateString, formatDateWithOffset, shouldUseLocalDate } from '../../utils';
import { getTooltipFormatter,  getLabelValueTooltip } from './commonConfig';
import formatters from './formatters';
import Highcharts from 'highcharts';
import { dateFilterFields } from '../../enums/filters';
import { getEndDateByInterval } from './utils';
import { getFilterNameByAlias } from '../../enums/crossfilter';

export function tooltip(...args) {
  const culture = this.culture;
  const { context } = args[args.length -1];
  return {
    tooltip: {
      formatter() {
        let header;
        if (this.point.series.chart.options.xAxis[0] && this.point.series.chart.options.xAxis[0].type === 'datetime') {
          const field = this.point.series.chart.options.xAxis[0].field;
          const formatter = shouldUseLocalDate(context.props.datasetIDs, field)
            ? formatDateString
            : formatDateWithOffset;
          header = formatter(this.x, standardDefaultFormats.date, culture);
        } else if (this.point.name) {
          header = this.point.name;
        } else {
          header = this.x;
        }

        const formatOptions = {
          value: this.y,
          culture,
          context,
        };
        const seriesValue = formatters.default(formatOptions);
        let s = `
          <span style="font-size: smaller">${header}</span><br>
          ${getLabelValueTooltip(this.series.name, seriesValue, this.point.color)}
        `;

        for (let i = 0; i < args.length - 1; i++) {
          const formatOptions = {
            value: this.point[args[i]],
            culture,
            context,
          };
          const value = formatters.default(formatOptions);
          s += `<span style="padding-left: 12px"><strong>${args[i]}</strong>: ${value}</span><br>`;
        }

        return s;
      },
    },
  };
}

export function xAxisDateFormatter(format, ...args) {
  const { context } = args[args.length -1];
  const culture = this.culture;
  const hasTooltip = this.kgs && this.kgs.some((s) => s.name === 'tooltip');
  let field;
  try {
    field = this.dataMapper[0].data.x.value;
  } catch (e) {
    field = '';
  }
  return {
    xAxis: {
      labels: {
        formatter() {
          const formatter = shouldUseLocalDate(context.props.datasetIDs, field)
            ? formatDateString
            : formatDateWithOffset;
          return formatter(this.value, format, culture);
        },
      },
      csvExporter(value) {
        const formatter = shouldUseLocalDate(context.props.datasetIDs, field)
          ? formatDateString
          : formatDateWithOffset;
        return formatter(value, format, culture);
      },
    },
    tooltip: hasTooltip
      ? this.tooltip
      : { ...this.tooltip, ...getTooltipFormatter({ format, culture, context }) },
  };
}


export function updateColors(pointToSelect, pointIsUnselecting, isCrossFilterDate, isSamePointCallback) {
  const color = Highcharts.color;
  const chartType = pointToSelect.series.type;
  const isSpecialType = ['area', 'areaspline', 'bellcurve', 'line','polygon', 'scatter', 'spline', 'waterfall'].includes(chartType);
  const isSamePoint = isSamePointCallback || ((point) => point === pointToSelect);

  pointToSelect.series.chart.series.forEach((series) => {
    if (isCrossFilterDate && isSpecialType) {
      const baseColor = color(series.color);
      series.update({
        color: pointIsUnselecting ? baseColor.setOpacity(1).get('rgba') : baseColor.setOpacity(0.2).get('rgba'),
        fillOpacity: pointIsUnselecting ? undefined : 0.2,
        custom: {
          ...series.userOptions.custom,
          isCrossFilterApplied: !pointIsUnselecting,
        },
      });
    }
    series.data.forEach((point) => {
      const isSame = isSamePoint(point);
      if (isCrossFilterDate && isSpecialType) {
        if (isSame) {
          const zIndex = Date.now();
          const baseColor = color(point.series.color);
          point.series.update({
            zIndex,
            color: baseColor.setOpacity(1).get('rgba'),
          });
        }
        point.update({
          marker: {
            enabled: isSame && !pointIsUnselecting,
          },
        }, false);
      } else {
        const basePointColor = point.zone && point.zone.color ? color(point.zone.color) : color(point.color);
        point.update({
          marker: {
            fillColor: isSame
              ? basePointColor.setOpacity(1).get('rgba') // brighten original color
              : pointIsUnselecting ? basePointColor.setOpacity(1).get('rgba') : basePointColor.setOpacity(0.2).get('rgba'),
          },
          color: isSame
            ? basePointColor.setOpacity(1).get('rgba') // brighten original color
            : pointIsUnselecting
              ? basePointColor.setOpacity(1).get('rgba')
              : basePointColor.setOpacity(0.2).get('rgba'), // dim orignal color
        }, false);
      }
    });
  });
  pointToSelect.series.chart.redraw(false);
}

export function updateColorsNetworkGraph(node, selected) {
  const color = Highcharts.color;
  if (!selected) {
    const baseColor = color(node.options.color);
    node.options.color = baseColor.setOpacity(0.2).get('rgba');
  }
}

export function crossFilter(...args) {
  const { context } = args[args.length -1];

  return {
    chart: {
      events: {
        click(evt) {
          context.handleChartClick(evt);
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          select: {
            enabled: false,
          },
        },
        allowPointSelect: false,
        cursor: 'pointer',
        point: {
          events: {
            contextmenu: function() {
              const point = this;
              context.handleContextmenu(point);
            },
            click(evt) {
              evt.stopPropagation();
              evt.preventDefault();
              
              const point = this;
              context.destroyContextMenuPopup();
              let seriesIndex, filterName, filterValue, filters;
              const isCrossFilterDate = args.some((s) => dateFilterFields.includes(s));
              const analysisInterval = context.props.config.analysisInterval;
              const interval = point.interval;

              if (isCrossFilterDate) {
                if (analysisInterval) {
                  const dateString = point.x;
                  const startDate = new Date(dateString);
                  const endDate = getEndDateByInterval(startDate, analysisInterval);
                  filterValue = {
                    diagnosticAnalysisInterval: analysisInterval,
                    value: 'custom_range',
                    range: {
                      start: startDate,
                      end: endDate,
                    },
                    x: dateString,
                  };
                } else if (interval) {
                  const dateString = point[args[0]];
                  const startDate = new Date(dateString);
                  const endDate = getEndDateByInterval(startDate, interval);
                  filterValue = {
                    value: 'custom_range',
                    range: {
                      start: startDate,
                      end: endDate,
                    },
                    x: dateString,
                  };
                }

                seriesIndex = 0;
                const secondaryName = typeof args[1] === 'string' ? args[1] : undefined;
                const secondaryValue = secondaryName ? point[secondaryName] : undefined;
                filters = [
                  {
                    filterValue,
                    filterName: args[seriesIndex],
                  },
                ];
                if (secondaryName &&  secondaryValue) {
                  filters = [...filters,
                    {
                      filterValue: secondaryValue,
                      filterName: secondaryName,
                    },
                  ];
                }
                context.handleCrossFilter(filters, point);
              } else if (point.options.type === 'networkgraph') {
                const { linksTo = [] } = evt.point;
                const { crossfilterField, crossfilterValue } = point.options;
                filterValue = crossfilterValue;
                filterName = getFilterNameByAlias(crossfilterField);
                filters = [
                  {
                    filterValue,
                    filterName,
                  },
                ];
                if (linksTo.length) {
                  linksTo.forEach((each) => {
                    filters.push({
                      filterValue: each.crossfilterValue,
                      filterName: getFilterNameByAlias(each.crossfilterField),
                    });
                  });
                }
                Object.assign(point, { crossfilterField, [filterName]: crossfilterValue });
                context.handleCrossFilter(filters, point, true);
              } else {
                seriesIndex = point.series.chart.series.findIndex((ser) => ser.data.includes(point), point);
                filterValue = point[args[seriesIndex]];
                filters = [
                  {
                    filterValue,
                    filterName: args[seriesIndex],
                  },
                ];
                context.handleCrossFilter(filters, point);
              }
            },
            mouseOver(evt) {
              const { series } = evt.target;
              const isCrossFilterApplied = series.userOptions.custom && series.userOptions.custom.isCrossFilterApplied;
              if (series.type === 'area' && !isCrossFilterApplied) {
                const zIndex = Date.now();
                const zIndexDefault = series.userOptions.zIndex;
                series.update({
                  zIndex,
                  custom: {
                    zIndexDefault,
                  },
                });
              }
            },
            mouseOut(evt) {
              const { series } = evt.target;
              const isCrossFilterApplied = series.userOptions.custom && series.userOptions.custom.isCrossFilterApplied;
              if (series.type === 'area' && !isCrossFilterApplied) {
                const zIndex = series.userOptions.custom.zIndexDefault;
                const zIndexDefault = zIndex;
                series.update({
                  zIndex,
                  custom: {
                    zIndexDefault,
                  },
                });
              }
            },
          },
        },
      },
      column: {
        states: {
          select: {
            enabled: false,
          },
        },
        allowPointSelect: false,
      },
    },
  };
}


export function pointLink(field, ...args) {
  const { context } = args[args.length -1];
  if (context.hasCrossfilter) {
    return {};
  }
  return {
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click(evt) {
              const url = this[field];
              context.handleDrillThrough(url);
              evt.stopPropagation();
              evt.preventDefault();
            },
          },
        },
      },
    },
  };
}

export function seriesLink(url) {
  return {
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click() {
              window.open(this[url], '_blank');
            },
          },
        },
      },
    },
  };
}
// called with the original configuration as this
export function xAxisByInterval(daily, weekly, monthly, ...args) {
  const { context } = args[args.length -1];
  const culture = this.culture;
  const hasTooltip = this.kgs && this.kgs.some((s) => s.name === 'tooltip');
  let format;
  switch (this.analysisInterval) {
    case 'Weekly':
      format = weekly;
      break;
    case 'Monthly':
      format = monthly;
      break;
    default:
      format = daily;
  }
  let field;
  try {
    field = this.dataMapper[0].data.x.value;
  } catch (e) {
    field = '';
  }

  return {
    xAxis: {
      labels: {
        formatter() {
          const formatter = shouldUseLocalDate(context.props.datasetIDs, field) && typeof this.value === 'string'
            ? formatDateString
            : formatDateWithOffset;
          return formatter(this.value, format, culture);
        },
      },
      csvExporter(value) {
        const formatter = shouldUseLocalDate(context.props.datasetIDs, field) && typeof value === 'string'
          ? formatDateString
          : formatDateWithOffset;
        return formatter(value, format, culture);
      },
    },
    tooltip: hasTooltip
      ? this.tooltip
      : { ...this.tooltip, ...getTooltipFormatter({ format, culture, context }) },
  };
}

export function seriesClick() {
  return {
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click() {
            this.color = '#FF0000';
          },
        },
      },
    },
  };
}

export function drilldown(...args) {
  const { context } = args[args.length -1];
  return {
    chart: {
      events: {
        drilldown(e) {
          if (!e.seriesOptions && (!e.points || e.points[0] == e.point)) {
            context.handleDrillDown(e.point);
          }
        },
        drillup() {
          context.handleDrillUp();
        },
      },
    },
  };
}
