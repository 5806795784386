import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getEquipmentModuleQueryString } from '../actionHelpers/equipmentHelpers';

// EQUIPMENT CONFIG
export function updateEquipmentModuleConfigColumns(payload) {
  return { type: types.UPDATE_EQUIPMENT_MODULE_CONFIG_COLUMNS, payload };
}

// GET EQUIPMENT LIST
export function setEquipmentModuleLoading(payload) {
  return { type: types.SET_EQUIPMENT_MODULE_LOADING, payload };
}

export function getEquipmentModuleSuccess(payload) {
  return { type: types.GET_EQUIPMENT_MODULE_SUCCESS, payload };
}

export function clearEquipmentModuleList() {
  return { type: types.CLEAR_EQUIPMENT_MODULE_LIST };
}

export function updateEquipmentModuleGridConfig(payload) {
  return { type: types.UPDATE_EQUIPMENT_MODULE_GRID_CONFIG, payload };
}

export function getEquipmentModule(payload) {
  return (dispatch) => getEquipmentModuleQueryString(payload)
    .then((qs) => api.getEquipmentModule(qs))
    .then((data) => {
      dispatch(setEquipmentModuleLoading(true));
      dispatch(getEquipmentModuleSuccess(data));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
    }).finally(() => {
      dispatch(setEquipmentModuleLoading(false));
    });
}

// Update Equipment Module Config
export function setEquipmentModuleConfigSaving(payload) {
  return { type: types.SET_EQUIPMENT_MODULE_CONFIG_SAVING, payload };
}

export function updateEquipmentModuleConfigSuccess(payload) {
  return { type: types.UPDATE_EQUIPMENT_MODULE_CONFIG_SUCCESS, payload };
}

export function updateEquipmentModuleConfig(config) {
  return (dispatch) => {
    dispatch(setEquipmentModuleConfigSaving(true));
    return api.updateEquipmentModuleConfig(config)
      .then((data) => {
        dispatch(updateEquipmentModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setEquipmentModuleConfigSaving(false));
      });
  };
}

export function resetEquipmentModuleJsonFilters() {
  return { type: types.RESET_EQUIPMENT_MODULE_JSON_FILTERS };
}

// Update Equipment
export function setEquipmentSaving(payload) {
  return { type: types.SET_EQUIPMENT_SAVING, payload };
}
export function updateEquipmentSuccess(payload) {
  return { type: types.UPDATE_EQUIPMENT_SUCCESS, payload };
}
export function updateEquipment(data) {
  const payload = {
    'EID': [data.eid],
    'resource': {
      'update': {
        'EquipmentName': data.equipmentName,
        'Active': data.active,
        'Visible': data.visible,
        'ConfigurationStatusID': data.configurationStatusId,
        'EquipmentTypeID': data.equipmentTypeId,
        'EquipmentNotes': data.equipmentNotes,
        'CMMSReferenceID': data.cmmsReferenceId,
        'CMMSLocationID': data.cmmsLocationId,
        'CMMSSiteID': data.cmmsSiteId,
        'CMMSLink': data.cmmsLink,

      },
    },
  };
  return (dispatch) => {
    dispatch(setEquipmentSaving(true));
    return api.updateEquipmentProfile(payload)
      .then((data) => {
        dispatch(updateEquipmentSuccess(payload));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setEquipmentSaving(false));
      });
  };
}
