import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { formatNumber } from '@telerik/kendo-intl';
import diagnosticsCellRenderers from './diagnosticsCellRenderers';
import editEquipmentFlagsCellRenderers from '../modals/editEquipmentFlags/editEquipmentFlagsCellRenderers';
import initialState from '../../reducers/initialState';
import { formatCurrency, formatDateWithOffset, getPredefinedInterval } from '../../utils';
import currencyOptions from '../../enums/currency';

const intervalOptions = ['Daily', 'Weekly', 'Monthly'];

export function getFilteredValueOrLength(filterValues, propertyName, label, isAllSelected) {
  let uniqueNamesArray = filterValues.map((filterValue) => filterValue[propertyName]);

  if (!isAllSelected) {
    const uniqueNamesSet = new Set(uniqueNamesArray);
    uniqueNamesArray = Array.from(uniqueNamesSet);
  }

  const count = uniqueNamesArray.length;
  const namesToJoin = isAllSelected ? filterValues.map((filterValue) => filterValue[propertyName]) : uniqueNamesArray;

  if (count > 4) {
    return `(${count}) ${label} Selected`;
  }

  return namesToJoin.join(', ');
}

export function createCellRenderer(column, opts) {
  const renderers = {
    ...diagnosticsCellRenderers,
    ...editEquipmentFlagsCellRenderers,
  };

  const cell = renderers[column.render];
  const options = { ...opts, ...column.renderOptions };
  return (innerProps) => typeof cell === 'function' ? cell({ ...innerProps, options }) : <GridCell {...innerProps} />;
}

export const colors = ['#ccdcfb', '#b3caf9', '#99b8f7', '#80a7f5', '#6695f2', '#4d83f0', '#3371ee', '#1a60ec', '#004eea'];

export const getSparklineChartOptions = ({ onPointClick, ...chartOptions }) => ({
  tooltip: {
    formatter() {
      const {
        culture,
        diagnosticDate,
        energyPriority,
        comfortPriority,
        maintenancePriority,
        avoidableCost,
        isoCurrencySymbol,
        notesSummary,
      } = this.point;
      const formattedEnergyPriority = energyPriority === 0 ? '0' : formatNumber(energyPriority, 'n', culture);
      const formattedComfortPriority = comfortPriority === 0 ? '0' : formatNumber(comfortPriority, 'n', culture);
      const formattedMaintenancePriority = maintenancePriority === 0 ? '0' : formatNumber(maintenancePriority, 'n', culture);
      const formattedDate = formatDateWithOffset(diagnosticDate, 'D', culture);
      const formattedCost = formatCurrency(avoidableCost, isoCurrencySymbol, culture, currencyOptions.wholeNumberSymbol);

      return `
        <span>
          <span class="is-flex is-size-7 mb-3">
            <b>${formattedDate}</b>
          </span>
          <span class="is-flex justify-content-between mb-3">
            <span class="is-flex flex-column align-items-center">
              <b class="is-size-6">${formattedCost}</b>
              cost
            </span>
            <span class="is-flex flex-column align-items-center">
              <b class="is-size-6" style="color: ${getPriorityColor(formattedEnergyPriority)};">
                ${formattedEnergyPriority}
              </b>
              energy
            </span>
            <span class="is-flex flex-column align-items-center">
              <b class="is-size-6" style="color: ${getPriorityColor(formattedComfortPriority)};">
                ${formattedComfortPriority}
              </b>
              comfort
            </span>
            <span class="is-flex flex-column align-items-center">
              <b class="is-size-6" style="color: ${getPriorityColor(formattedMaintenancePriority)};">
                ${formattedMaintenancePriority}
              </b>
              maint.
            </span>
          </span>
          <span>
            ${notesSummary || '-'}
          </span>
        </span>
      `;
    },
  },
  plotOptions: {
    series: {
      cursor: 'pointer',
      point: {
        events: {
          click() {
            const point = this;
            onPointClick(point);
          },
        },
      },
    },
  },
  ...chartOptions,
});

export function getVisibleColumns(columns) {
  return columns.filter((column) => column.show);
}

export const getPriorityColor = (priority) => priority >= 8 ? '#D95029' : '#868686';

export function getDiagnosticsFiltersFromQueryString(searchObject) {
  const newJsonFilters = [];
  const defaultJsonFilters = initialState.diagnosticsModule.config.jsonFilters;
  if (searchObject.unitid) {
    const unitids = Array.isArray(searchObject.unitid) ? searchObject.unitid : [searchObject.unitid];
    newJsonFilters.push({
      key: 'client',
      value: unitids.map(Number),
    });
  }
  if (searchObject.bid) {
    const bids = Array.isArray(searchObject.bid) ? searchObject.bid : [searchObject.bid];
    newJsonFilters.push({
      key: 'building',
      value: bids.map(Number),
    });
  }
  if (searchObject.ecid) {
    const ecids = Array.isArray(searchObject.ecid) ? searchObject.ecid : [searchObject.ecid];
    newJsonFilters.push({
      key: 'equipmentClass',
      value: ecids.map(Number),
    });
  }
  if (searchObject.etid) {
    const etids = Array.isArray(searchObject.etid) ? searchObject.etid : [searchObject.etid];
    newJsonFilters.push({
      key: 'equipmentType',
      value: etids.map(Number),
    });
  }
  if (searchObject.eid) {
    const eids = Array.isArray(searchObject.eid) ? searchObject.eid : [searchObject.eid];
    newJsonFilters.push({
      key: 'equipment',
      value: eids.map(Number),
    });
  }
  if (searchObject.aid) {
    const aids = Array.isArray(searchObject.aid) ? searchObject.aid : [searchObject.aid];
    newJsonFilters.push({
      key: 'analysis',
      value: aids.map(Number),
    });
  }
  if (searchObject.rng) {
    newJsonFilters.push({
      key: 'diagnosticAnalysisInterval',
      value: intervalOptions.find((option) => option.toLowerCase() === searchObject.rng.toLowerCase()),
    });
  } else {
    newJsonFilters.push(defaultJsonFilters.find(({ key }) => key === 'diagnosticAnalysisInterval'));
  }
  if (searchObject.sd || searchObject.ed) {
    const start = searchObject.sd
      ? searchObject.sd
      : searchObject.ed;
    const end = searchObject.ed
      ? searchObject.ed
      : searchObject.sd;
    newJsonFilters.push({
      key: 'diagnosticDate',
      value: {
        value: 'custom_range',
        range: { start, end },
      },
    });
  } else {
    newJsonFilters.push({
      'key': 'diagnosticDate',
      'value': {
        'value': 'last_7_days',
        'range': {},
      },
    });
  }
  return newJsonFilters;
}

export function getAllAvailableOrAppliedFilters(dashboardFilters, queryParams) {
  const { equipment, building, analysis, equipmentClass } = dashboardFilters;
  const {
    BID: appliedBuildingIds = [],
    EID: appliedEquipmentIds = [],
    AID: appliedAnalysesEquipmentIds = [],
    EquipmentClassID: appliedEquipmentClassID = [],
  } = queryParams;

  const appliedEquipments = equipment.filter((equip) => appliedEquipmentIds.includes(equip.id));
  const equipments = appliedEquipments.length ? appliedEquipments : equipment;

  const appliedBuildings = building.filter((build) => appliedBuildingIds.includes(build.bid));
  const buildings = appliedBuildings.length ? appliedBuildings : building;

  const appliedAnalyses = analysis.filter((analyse) => appliedAnalysesEquipmentIds.includes(analyse.aid));
  const getAnalyses = appliedAnalyses.length ? appliedAnalyses : analysis;

  const appliedEquipmentClass = equipmentClass.filter((equipmentClas) => appliedEquipmentClassID.includes(equipmentClas.id));
  const getEquipmentClass = appliedEquipmentClass.length ? appliedEquipmentClass : equipmentClass;

  return { equipments, buildings, analyses: getAnalyses, equipmentClass: getEquipmentClass };
}

export function getFlattenedEquipmentAnalysis(equipmentAnalysisAll, analyses) {
  // Create a hash map of analyses for faster lookup
  const analysisMap = Object.fromEntries(analyses.map((analysis) => [analysis.aid, analysis]));

  return equipmentAnalysisAll.flatMap(({ eid, aids }) =>
    aids
      .filter((aid) => analysisMap[aid])
      .map((aid) => ({
        analysisId: aid,
        analysisName: analysisMap[aid].analysisName,
        equipmentId: eid,
      })),
  );
}

export function getDiagnosticIntervalAndDate(jsonFiltersWithDefault) {
  const diagnosticAnalysisInterval = jsonFiltersWithDefault.find((e) => e.key === 'diagnosticAnalysisInterval');
  const diagnosticIntervalStartDate = getPredefinedInterval(diagnosticAnalysisInterval.value);

  return { analysisInterval: diagnosticAnalysisInterval.value, diagnosticDate: diagnosticIntervalStartDate.start };
}

export function getOrganization(client, unitId) {
  const selectedOrganization = client.find((item) => item.unitId === unitId);
  const organization = { organizationId: selectedOrganization.unitId, organizationName: selectedOrganization.unitName };

  return organization;
}
