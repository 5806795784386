import { formatDate, formatNumber } from '@telerik/kendo-intl';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { widgetDatasets } from '../enums/datasets';
import { Day, addDays, addWeeks, prevDayOfWeek, firstDayOfMonth, addMonths } from '@progress/kendo-date-math';

export function scrollToFormField(formRef, formField) {
  const el = formRef.current.elements[formField];
  if (el) el.scrollIntoView();
}

export function getCookieByName(name) {
  const dc = `; ${document.cookie};`;
  const prefix = `${name}=`;
  let begin = dc.indexOf(`; ${prefix}`);
  if (begin === -1) {
    return null;
  }
  begin += 2;
  const end = dc.indexOf(';', begin);
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function updatedArrayOrOriginalArray(idName, arrayToUpdate, updatedItem) {
  const listIndex = arrayToUpdate.findIndex((item) => item[idName] === updatedItem[idName]);
  const newList = listIndex === -1 ? arrayToUpdate : [...arrayToUpdate.slice(0, listIndex), updatedItem, ...arrayToUpdate.slice(listIndex + 1)];
  return newList;
}

// accepts a utc date string and returns a new date object with the local timezone offset added to the date
export function utcStringToDatePlusOffset(dateString) {
  const dateUTC = new Date(dateString);
  const localOffset = dateUTC.getTimezoneOffset();
  return new Date(dateUTC.getTime() + (localOffset * 60000));
}

export function utcStringToDateMinusOffset(dateString) {
  const dateUTC = new Date(dateString);
  const localOffset = dateUTC.getTimezoneOffset();
  return new Date(dateUTC.getTime() - (localOffset * 60000));
}

// accepts js date object, returns js date object without time
export function getDateWithoutTime(jsDateObject) {
  const year = jsDateObject.getFullYear();
  const monthIndex = jsDateObject.getMonth();
  const day = jsDateObject.getDate();
  const dateWithoutTime = new Date(year, monthIndex, day);
  return dateWithoutTime;
}

// accepts date, returns ISO Date String
export function convertToISOString(dateString) {
  let isoStringOrNull = null;
  try {
    isoStringOrNull = new Date(dateString).toISOString();
  } catch (err) {
    isoStringOrNull = dateString;
  }
  return isoStringOrNull;
}

// valid dateString or jsDateObject
export function startOfDay(date) {
  const jsDateObject = typeof date === 'string' ? new Date(date) : date;
  return getDateWithoutTime(jsDateObject);
}

export function formatErrorMessage(message, type) {
  return message.replace('This', type);
}

export function formatDateIfDefined(jsDateObject, format = 'y-MM-dd') {
  return jsDateObject ? formatDate(jsDateObject, format) : undefined;
}

export function firstDayOfMonthISO(isoString) {
  return `${isoString.slice(0, 8)}01T00:00:00.000Z`;
}

/**
 * Template parser function.
 *
 * @param {string} template - string, default ''
 * @param {object} data - object, default {}
 * @return {string} string, parsed result
 *
 * @example
 *    const template = "fname={{firstName}}&lname={{lastName}}"
 *    const data = { firstName: "John", lastName: "Doe" }
 *    const result = templateParser(template, data)
 *    // result = "fname=John&lname=Doe"
 */
export function templateParser(template = '', data = {}) {
  const pattern = /{{\s*(\w+?)\s*}}/g;
  return template.replace(pattern, (_, token) => data[token] || '');
}

/**
 * @param {string} fullString - string to be search against
 * @param {string} searchKeyword - search keyword
 * @return {boolean} - true if there's a match, otherwise false
 */
export function searchForMatchKeyword(fullString, searchKeyword) {
  const fullStringLower = fullString.toLocaleLowerCase();
  const searchKeywordLower = searchKeyword.toLocaleLowerCase();
  const searchKeywordTrimmed = searchKeywordLower.trim();

  // has match
  if (!searchKeywordTrimmed) return true;

  const doubleQuotesMatchArray = searchKeywordTrimmed.match(/".*?"/g) || [];
  const doubleQuotesKeywordsArray = doubleQuotesMatchArray.filter((keyword) => keyword).map((keyword) => keyword.replace(/"/g, '')).filter((keyword) => keyword);
  const doubleQuotesKeywordsMatch = doubleQuotesKeywordsArray.some((keyword) => fullStringLower.includes(keyword));

  // has match
  if (doubleQuotesKeywordsMatch) return true;

  let searchKeywordMinusDoubleQuotedTexts = searchKeywordTrimmed;
  doubleQuotesMatchArray.forEach((keyword) => {
    const keywordEscaped = `${keyword}`.replace(/[-[\]/{}()*+?.^$|]/g, '\\$&');
    searchKeywordMinusDoubleQuotedTexts = searchKeywordMinusDoubleQuotedTexts.replace(new RegExp(keywordEscaped, 'g'), '');
  });

  const orKeywordsArray = searchKeywordMinusDoubleQuotedTexts.split(' ').filter((keyword) => keyword);
  const orKeywordsMatch = orKeywordsArray.some((keyword) => fullStringLower.includes(keyword));

  // if orKeywordsMatch is true: has match, otherwise no match
  return orKeywordsMatch;
}

export function formatCurrency(value, isoCode, culture, formatOptions = {}) {
  const formattedValue = formatNumber(value, {
    style: 'currency',
    currency: isoCode,
    currencyDisplay: formatOptions.currencyDisplay || 'code',
    ...formatOptions,
  }, culture);
  const isoCodeWithSpace = formattedValue.indexOf(isoCode) === 0 ? `${isoCode} ` : ` ${isoCode}`;
  return formattedValue.replace(isoCode, isoCodeWithSpace);
}

export function isValidDateInMs(dateinMs) {
  if (!dateinMs) return false;
  let isValid = false;
  // 12 or 13 length integer
  const length = String(dateinMs).length;
  if (Number.isInteger(dateinMs) && (length === 12 || length === 13)) {
    try {
      const date = new Date(dateinMs);
      isValid = date !== 'Invalid Date';
    } catch (e) {
      isValid = false;
    }
  }

  return isValid;
}

export function isValidISODateString(isoString) {
  if (!isoString) return false;
  let isValid = false;
  // "2022-07-11T00:00:00Z"
  const pattern1 = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;
  // "2022-07-11 00:00:00"
  const pattern2 = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9]) (2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?$/;

  if (pattern1.test(isoString) || pattern2.test(isoString)) {
    try {
      const date = new Date(isoString);
      isValid = date !== 'Invalid Date';
    } catch (e) {
      isValid = false;
    }
  }

  return isValid;
}

// validate if yyyy-mm-dd and can be parsed to JS Date Object
export function isValidDateString(dateString) {
  if (!dateString) return false;
  const datePart = String(dateString || '').slice(0, 10);
  const regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  let isValid = false;

  if (regex.test(datePart)) {
    const date = new Date(datePart);
    isValid = Boolean(date instanceof Date && !isNaN(date));
  }

  return isValid;
}

export const formatDateString = (dateString, format, culture) => formatDate(new Date(dateString), format, culture);

export const formatDateWithOffset = (value, format, culture) => formatDate(utcStringToDatePlusOffset(value), format, culture);

export function addToList(list, id) {
  return Array.from(new Set([...list, id]));
}

export function removeFromList(list, id) {
  return list.filter((item) => item !== id);
}

export function generateUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
}

export const areFiltersEqual = (filters1 = [], filters2 = []) => {
  let isEqual = filters1.length === filters2.length;

  filters1.forEach((item) => {
    if (!isEqual) return;

    const found = filters2.find((item2) => item2.filterName === item.filterName);
    if (!found) {
      isEqual = false;
      return;
    }

    if (item.filterValue && item.filterValue.range && found.filterValue.range) {
      const { start, end } = item.filterValue.range || {};
      const { start: start2, end: end2 } = found.filterValue.range || {};
      isEqual = `${start}` === `${start2}` && `${end}` === `${end2}`;
      return;
    }

    isEqual = item.filterValue === found.filterValue;
  });

  return isEqual;
};

export function trimText(text = '', threshold) {
  if (text.length <= threshold) return text;
  return text.substr(0, threshold).concat('...');
}

export function getLastXDaysRange(inputValue) {
  const today = new Date();
  return {
    start: formatDate(addDays(today, -inputValue), 'd'),
    end: null,
  };
}

export function setPercentage(gridWidth, percentage) {
  return (gridWidth / 100) * percentage;
}

export function getPercentage(gridWidth, columnWidth) {
  return (columnWidth / gridWidth) * 100;
}

export function setEqualPercentage(gridWidth, columns) {
  return gridWidth / columns;
}

export function shouldUseLocalDate(datasetIDs, field) {
  let useLocal = false;
  if (datasetIDs.includes(widgetDatasets.Rawdatadataset) && field !== 'dateLoggedLocal') {
    // Raw Data
    useLocal = true;
  } else if (datasetIDs.includes(widgetDatasets.VDataDataset) && field !== 'dateLoggedLocal') {
    // VData
    useLocal = true;
  } else if (datasetIDs.includes(widgetDatasets.DiagnosticResultsVDataAdx) && field !== 'dateLoggedLocal') {
    // VDataAdx
    useLocal = true;
  }
  return useLocal;
}

export function getNumOfAppliedFilters(jsonFilters = []) {
  let count = 0;
  jsonFilters.forEach(({ value }) => {
    if (Array.isArray(value) && value.length) {
      count += 1;
    } else if (typeof value === 'object' && value && value.value && value.value !== 'All') {
      count += 1;
    } else if (typeof value === 'string' && value && value !== 'All') {
      count += 1;
    } else if (typeof value === 'number' && value) {
      count += 1;
    }
  });
  return count;
}

export const mapper = (array, { inputValueKey, inputLabelKey, outputValueKey, outputLabelKey }) => {
  let set = new Set([]);
  for (const e of new Set(array)) {
    set.add({ [outputValueKey]: e[inputValueKey], [outputLabelKey]: e[inputLabelKey] });
  }
  return Array.from(set);
};

export const toQueryString = (obj) => {
  const parts = [];
  for (const i in obj) {
    if (obj[i] !== undefined && obj[i] !== null) {
      parts.push(`${i}=${obj[i]}`);
    }
  }
  return parts.join('&');
};

// queryString must start with "?"
export const parseQueryString = (queryString) => {
  const params = new URLSearchParams(queryString);
  const result = {};

  params.forEach((value, key) => {
    // Check if the value contains a comma to determine if it's a list
    const values = value.split(',');

    if (values.length > 1) {
      // If there are multiple values, store them as an array
      result[key] = values;
    } else {
      // If there's a single value, store it directly
      result[key] = value;
    }
  });

  return result;
};

export const isLinkUrlSameOrigin = (url) => url.includes(location.origin);
export const replaceLinkUrlOrigin = (url) => url.replace(location.origin, '');

export function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

function isOverflowing() {
  return document.body.scrollHeight > document.body.clientHeight;
}

export function getTableWidth(prevWidth, tableSelector) {
  const tableElement = document.querySelector(tableSelector);
  const clientWidth = tableElement.clientWidth;
  const overflowing = isOverflowing();
  const scrollbarWidth = getScrollbarWidth();

  const result = overflowing && (prevWidth - scrollbarWidth) === clientWidth
    ? prevWidth
    : clientWidth;

  return result;
}

export function mergeArrayById(firstArray, secondArray, firstArrayId, secondArrayId) {
  // Create a map to look up a piece of secondArray by id
  const mapByProp = new Map();
  secondArray.forEach((obj) => {
    mapByProp.set(obj[secondArrayId], obj);
  });

  // Map over firstArray and merge the secondArray if found
  const mergedArray = firstArray.map((obj) => {
    const matchingObj = mapByProp.get(obj[firstArrayId]);
    if (!matchingObj) return undefined; // Skip if no matching object

    // Destructure excluding the property matching secondArrayId
    // eslint-disable-next-line no-unused-vars
    const { [secondArrayId]: _, ...restOfMatchingObj } = matchingObj;
    return { ...obj, ...restOfMatchingObj };
  }).filter((obj) => obj !== undefined);

  return mergedArray;
}

export function getPredefinedInterval(customValue) {
  const today = new Date();
  const defaultValue = {
    start: null,
    end: null,
  };
  // Subtract one month from the current date
  const lastMonthDate = addMonths(today, -1);
  const lastWeekDate = addWeeks(today, -1);

  const dateMapping = {
    Daily: {
      start: addDays(today, -1),
      end: today,
    },
    Weekly: {
      start: prevDayOfWeek(lastWeekDate, Day.Sunday),
      end: today,
    },
    Monthly: {
      start: firstDayOfMonth(lastMonthDate),
      end: today,
    },
  };

  return dateMapping[customValue] || defaultValue;
}

export function getGridColumnWidthInPx(column, gridWidth) {
  return column.widthInPx ? column.widthInPx : setPercentage(gridWidth - 34, column.width);
}

export function encodeAngledBrackets(text) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function formatNumberValueWithCulture (value, culture) {
  return !value || isNaN(Number(value))
    ? value
    : formatNumber(Number(value), 'n', culture);
}

export function htmlParser(string, ...args) {
  return parse(DOMPurify.sanitize(string), ...args);
}

export function extractTextFromHtml(htmlString) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText;
}
