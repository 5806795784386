import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';

function NotesCustomCell({ cellprops }) {
  const { field, dataItem, options = {} } = cellprops;
  const equipmentNotes = dataItem[field];

  const [show, setShow] = useState(false);
  const anchor = useRef(null);

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  if (!equipmentNotes) {
    return (<td></td>);
  }

  return (
    <td>
      <Popup
        anchorAlign={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchor={anchor.current}
        show={show}
        animate={false}
      >
        <div className="notes-cell-popup-content">
          {equipmentNotes}
        </div>
      </Popup>
      <button
        className="button is-medium notes-cell-icon"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={anchor}
        onClick={() => options.openViewModal(dataItem, { defaultSelectedTab: 2 })}
        style={{ height: '20px' }}
      >
        <span className="icon">
          <i className="flaticon-writing" aria-hidden="true"></i>
        </span>
      </button>
    </td>
  );
}

NotesCustomCell.propTypes = {
  field: PropTypes.string.isRequired,
  cellprops: PropTypes.object.isRequired,
};


export default NotesCustomCell;
