import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import IconButton from '../common/IconButton';
import BuildingDetails from '../buildings/buildingModal/BuildingDetails';
import { useBuildingByIdPermissions } from '../buildings/buildingModal/hooks';
import { updateModal } from '../../actions/modalActions';
import { userResources } from '../../enums/resources';

const BuildingModal = ({ dataItem, culture, cancelCallback }) => {
  const dispatch = useDispatch();
  const resources = useSelector((state) => state.user.resources);
  const { data: buildingByIdUpdatePermissions, loading } = useBuildingByIdPermissions(dataItem.bid);

  const hasSystemAdminReadPermission = useMemo(
    () => resources.includes(userResources.SystemAdministration),
    [resources],
  );

  const canEditBuilding = buildingByIdUpdatePermissions.u === true && hasSystemAdminReadPermission === true;

  const showDetailsModal = (details) => {
    dispatch(
      updateModal(
        'BUILDING_MODAL',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback,
          dataItem: details,
        },
      ),
    );
  };

  const showEditModal = () => {
    dispatch(
      updateModal(
        'EDIT_BUILDING',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback: () => showDetailsModal(dataItem),
          saveCallback: (response) => showDetailsModal(response),
          dataItem,
        },
      ),
    );
  };

  return (
    <div className="box modal-box">
      <div className="modal-header d-flex justify-content-between align-items-center mb-4">
        <div className="modal-header">
          <h1 className="title mb-0">
            {dataItem.buildingName}
          </h1>
        </div>
        <div>
          <IconButton
            title="Edit Building"
            iconName="flaticon-edit"
            className={classNames('button' && { 'is-loading': loading })}
            disabled={!canEditBuilding}
            onClick={showEditModal}
          />
        </div>
      </div>

      <div className="box modal-main building-report-modal-container">
        <BuildingDetails
          details={dataItem}
          culture={culture}
          hasSystemAdminReadPermission={hasSystemAdminReadPermission}
        />
      </div>

      <div className="modal-footer is-relative d-flex align-items-center justify-content-center">
        <div>
          <button
            className='button is-info is-outlined is-medium mr-2'
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>

    </div>
  );
};

BuildingModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default BuildingModal;
