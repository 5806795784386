/* eslint-disable react/prop-types */
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { addDays } from '@progress/kendo-date-math';
import { formatDate } from '@telerik/kendo-intl';
import TextArea from '../common/TextArea';
import SelectInput from '../common/SelectInput';
import MultiSelectInput from '../common/MultiSelectInput';
import DatePickerInput from '../common/DatePickerInput';
import LabelValue from '../common/LabelValue';
import { hideModal, updateModal } from '../../actions/modalActions';
import { clearReportersAndAssignees, createTask, getReportersAndAssignees, getTaskPermissions } from '../../actions/taskModuleActions';
import { createTaskModalSelector } from '../../selectors/createTaskModal';
import { getErrorMessage } from '../../api/apiErrorHandler';
import toMap from '../../utils/toMap';
import { utcStringToDatePlusOffset } from '../../utils';
import { intervalOptions } from '../tasks/utils';
import { getDateInterval } from '../../actionHelpers/diagnosticHelpers';
import { clearBuildingSettings, getBuildingSettings } from '../../actions/buildingSettingsActions';

const CreateTaskModal = (props) => {
  const {
    data, clientAll: allOrgs, buildingAll: allBuildings,
    buildings: unitBuildings, equipmentAll: allEquipments, assigneesAndReporters, assigneesAndReportersLoading,
    equipmentAnalysisAll, taskStatuses, analyses, buildingSettings,
    saving, dispatch, cancelCallback, saveCallback,
  } = props;
  const { defaultData, diagnosticDate } = data;
  const templateName = defaultData ? defaultData.notesSummary : data.templateName;
  const yesterday = addDays(new Date(), -1);
  const appliedQueryParams = setValuesToArray(data.appliedQueryParams, ['UnitID', 'BID', 'EID']);

  const createTaskModalRef = useRef(null);
  const [orgTouched, setOrgTouched] = useState(false);
  const [org, setOrg] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [assignee, setAssignee] = useState('');
  const [status, setStatus] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const [actions, setActions] = useState('');
  const [hasNotification, setHasNotification] = useState(false);
  const [hasGenerateWorkOrder, setHasGenerateWorkOrder] = useState(false);
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [diagnosticOptions, setDiagnosticOptions] = useState([]);
  const [isDiagnosticOptionsExpanded, setIsDiagnosticOptionsExpanded] = useState(false);

  const isEnableCreateWorkOrder = useMemo(
    () => buildingSettings.length > 0 && buildingSettings.every((setting) => setting.isEnableCreateWorkOrder),
    [buildingSettings],
  );

  const equipmentsWithCMMSReferenceId = useMemo(
    () => allEquipments.filter((equipment) => equipments.some((e) => e === equipment.id) && equipment.cmmsReferenceId),
    [equipments, allEquipments],
  );

  const hasCMMSReferenceId = equipmentsWithCMMSReferenceId.length > 0;

  const canCreateWorkOrder = isEnableCreateWorkOrder && hasCMMSReferenceId;

  const appliedOrgs = useMemo(() => allOrgs.filter((org) => appliedQueryParams.UnitID.includes(org.unitId)),
    [allOrgs, appliedQueryParams],
  );
  const orgOptions = useMemo(() => mapper(
    appliedOrgs, {
      inputValueKey: 'unitId',
      inputLabelKey: 'unitName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [appliedOrgs]);

  const appliedBuildings = useMemo(
    () => allBuildings.filter((building) => {
      if (!org) return false;
      const isApplied = appliedQueryParams.BID.includes(building.bid);
      if (!isApplied) return false;
      const buildingInfo = unitBuildings.find((e) => e.bid === building.bid);
      if (!buildingInfo) return false;
      return String(buildingInfo.unitId) === String(org);
    }),
    [allBuildings, appliedQueryParams, unitBuildings, org],
  );
  const buildingOptions = useMemo(() => mapper(
    appliedBuildings, {
      inputValueKey: 'bid',
      inputLabelKey: 'buildingName',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [appliedBuildings]);

  const equipmentAnalysisAllToMap = useMemo(
    () => toMap(equipmentAnalysisAll, 'eid'),
    [equipmentAnalysisAll],
  );

  const appliedEquipments = useMemo(
    () => allEquipments.filter((equipment) => {
      if (!org) return false;
      if (!buildings.length) return false;
      if (appliedQueryParams.EID.length) {
        const isApplied = appliedQueryParams.EID.includes(equipment.id);
        if (!isApplied) return false;
      }
      const inBuilding = buildings.includes(equipment.buildingId);
      if (!inBuilding) return false;

      const equipmentAnalysis = equipmentAnalysisAllToMap.get(equipment.id);
      if (!equipmentAnalysis) return false;
      if (!equipmentAnalysis.aids || equipmentAnalysis.aids.length === 0) return false;
      return equipmentAnalysis.aids;
    }),
    [allEquipments, appliedQueryParams, buildings],
  );

  const equipmentOptions = useMemo(() => mapper(
    appliedEquipments, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [appliedEquipments]);

  const appliedAssignees = assigneesAndReporters;

  const assigneeOptions = useMemo(() => mapper(
    appliedAssignees, {
      inputValueKey: 'uid',
      inputLabelKey: 'email',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [appliedAssignees]);

  const appliedStatuses = taskStatuses;

  const statusOptions = useMemo(() => mapper(
    appliedStatuses, {
      inputValueKey: 'statusId',
      inputLabelKey: 'statusName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [appliedStatuses]);

  const checkOrgCreateTaskAccess = useCallback(async (unitId) => {
    try {
      const permissions = await dispatch(getTaskPermissions(unitId));
      if (!permissions.c) showCreateTaskError();
    } catch (error) {
      showCreateTaskError();
    }
  }, []);

  const hideTaskModal = () => dispatch(hideModal());

  const showCreateTaskError = useCallback(() => {
    dispatch(
      updateModal(
        'NOTIFICATION',
        {
          type: 'error',
          title: 'Error',
          message: 'Your account has not been configured for task creation for this organization. Please contact your system administrator to review your account access.',
          yesLabel: 'OK',
          yesCallback: hideTaskModal,
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (defaultData) {
      setOrg(defaultData.organizationId);
      setBuildings([defaultData.buildingId]);
      setEquipments([defaultData.equipmentId]);
    }
  }, [defaultData]);

  useEffect(() => {
    setSummary(templateName);
  }, [templateName]);

  useEffect(() => {
    if (!defaultData && orgOptions.length === 1) {
      setOrg(orgOptions[0].value);
    }
  }, [defaultData, orgOptions]);

  useEffect(() => {
    if (!defaultData) {
      if (org) {
        checkOrgCreateTaskAccess(org);
        setOrgTouched(true);
      }
      setBuildings([]);
      setAssignee('');
      dispatch(clearReportersAndAssignees());
    }
  }, [defaultData, org]);

  useEffect(() => {
    if (!defaultData) {
      setEquipments([]);
      dispatch(clearReportersAndAssignees());
    }
  }, [defaultData, buildings]);

  useEffect(() => {
    if (org && buildings.length) {
      dispatch(getReportersAndAssignees(org, buildings));
      dispatch(getBuildingSettings(buildings));
    } else {
      dispatch(clearReportersAndAssignees());
      dispatch(clearBuildingSettings());
    }
    return () => {
      dispatch(clearBuildingSettings());
    };
  }, [org, buildings]);

  useEffect(() => {
    if (equipments.length) {
      populateEquipmentChange(equipments);
    }
  }, [equipments]);

  useEffect(() => {
    let valid = false;
    if (
      org && buildings.length && equipments.length &&
      assignee && status && summary
    ) {
      let diagnosticDateCheckerFlag = true;
      diagnosticOptions.forEach((option) => {
        if (!(option.date instanceof Date)) {
          diagnosticDateCheckerFlag = false;
        }
      });
      if (diagnosticDateCheckerFlag) {
        valid = true;
      }
    }
    setIsValid(valid);
  }, [org, buildings, equipments, assignee, status, summary, diagnosticOptions]);

  useEffect(() => {
    if (
      assignee &&
      assigneeOptions.length &&
      !assigneeOptions.some((s) => String(s.value) === String(assignee))
    ) {
      setAssignee('');
    }
  }, [assigneeOptions]);

  const getAnalysisEquipment = () => diagnosticOptions.length === 0
    ? []
    : diagnosticOptions.map((option, index) => ({
      EID: Number(equipments[index]),
      AID: Number(option.analysis),
      AnalysisInterval: option.interval,
      CMMSReferenceID: option.cmmsReferenceId,
      DiagnosticDate: formatDate(option.date, 'yyyy-MM-dd'),
    }));

  const handleCreateTask = () => {
    const analysisEquipment = getAnalysisEquipment();
    const payload = {
      Meta: {
        HasNotification: hasNotification,
      },
      AnalysisEquipment: analysisEquipment,
      Resource: {
        OrganizationID: Number(org),
        AssignedUID: Number(assignee),
        StatusID: Number(status),
        Summary: summary,
        Description: description,
        Recommendations: recommendations,
        Actions: actions,
        HasGenerateWorkOrder: hasGenerateWorkOrder,
      },
    };
    createTaskCallback(payload);
  };

  const createTaskCallback = (payloadObject) => {
    dispatch(createTask(payloadObject))
      .then(() => {
        if (saveCallback) {
          saveCallback();
        } else {
          hideTaskModal();
        }
        toastr.success('Successfully created', 'Task(s) have been successfully created.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
      }).catch((err) => {
        let errorMessage = 'Fetching dataset failed.';
        if (err.status === 422 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
          errorMessage = getErrorMessage(err);
        } else if (err.json && err.json.Meta && err.json.Meta.EventGUID) {
          errorMessage = `We are unable to accommodate your request right now. Please contact customer support with the following: EventGuid: ${err.json.Meta.EventGUID}`;
        }
        setError(errorMessage);
      });
  };

  const handleChangeInput = (setter) => (event) => {
    const { value, checked } = event.target;
    const newValue = typeof checked === 'boolean' ? checked : value;
    setter(newValue);
  };

  const handleChangeDiagnosticInput = (event, index) => {
    const { name, value } = event.target;
    let diagnosticOptionsArr = diagnosticOptions.slice();
    diagnosticOptionsArr[index][name] = value;
    if (name === 'date' && value != null) {
      diagnosticOptionsArr[index].interval = getDateInterval(value);
    }
    setDiagnosticOptions(diagnosticOptionsArr);
  };

  const handleExpandDiagnosticOptions = () => {
    setIsDiagnosticOptionsExpanded(!isDiagnosticOptionsExpanded);
  };

  const populateEquipmentChange = (equipmentIds) => {
    setIsDiagnosticOptionsExpanded(false);
    let diagnosticArr = [];
    equipmentIds.forEach((equipmentId) => {
      const equipmentAnalysis = equipmentAnalysisAll.find((eq) => eq.eid === equipmentId);
      const equipmentName = equipmentOptions.find((eq) => eq.value === equipmentAnalysis.eid).label;
      const diagnosticAnalyses = equipmentAnalysis.aids;
      let diagnosticAnalysisOptionsArr = diagnosticAnalyses.map((e) => ({ value: e, text: analyses.find((analysis) => analysis.aid === e).analysisName }));
      diagnosticAnalysisOptionsArr.sort(function(a, b){
        if(a.text < b.text) { return -1; }
        if(a.text > b.text) { return 1; }
        return 0;
      });

      if (diagnosticAnalysisOptionsArr.length > 0) {
        const newDiagnostic = {
          equipmentName,
          equipmentId,
          analysis: defaultData ? defaultData.analysisId : diagnosticAnalysisOptionsArr[0].value,
          analysisOptions: diagnosticAnalysisOptionsArr,
          interval: appliedQueryParams.DiagnosticAnalysisInterval || 'Daily',
          date: diagnosticDate ? utcStringToDatePlusOffset(diagnosticDate) : yesterday,
          cmmsReferenceId: allEquipments.find((eq) => eq.id === equipmentId).cmmsReferenceId,
        };

        diagnosticArr.push(newDiagnostic);
      }
    });
    setDiagnosticOptions(diagnosticArr);
  };

  const orgDefaultOption = org || orgTouched ? 'Select Organization' : 'A single organization selection is required';
  const taskCount = equipments.length;
  const workOrderCount = equipmentsWithCMMSReferenceId.length;

  return (
    <form data-testid="CreateTaskModal" onSubmit={(evt) => evt.preventDefault()} className="box field-body--no-padding modal-box" name="createTaskModal" ref={createTaskModalRef}>
      <div className="modal-header mb-4" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title">Create Task</h1>
      </div>

      {error && <div>
        <h6 style={{ marginBottom: '10px' }} className="is-6 subtitle has-text-danger">{error}</h6>
      </div>}

      <div className="box modal-main create-task-body-container">

        {defaultData ? (
          <LabelValue
            label={'Organization'}
            title={defaultData.organizationName}
            value={<div className='input-disable'>{defaultData.organizationName}</div>}
          />
        ) : (
          <SelectInput
            label={'*Organization'}
            isLoading={false}
            options={orgOptions}
            defaultOption={orgDefaultOption}
            name={'org'}
            value={org}
            onChange={handleChangeInput(setOrg)}
          />
        )}

        {defaultData ? (
          <LabelValue
            label={'Buildings'}
            title={defaultData.buildingName}
            value={<div className='input-disable'>{defaultData.buildingName}</div>}
          />
        ) : (
          org ? (
            <MultiSelectInput
              name={'buildings'}
              label={'*Buildings'}
              options={buildingOptions}
              value={buildings}
              onChange={setBuildings}
              disabled={assigneesAndReportersLoading}
            />
          ) : (
            <LabelValue
              label={'*Buildings'}
              value={<div className='input-disable-text'>Please select at least 1 Organization</div>}
            />
          )
        )}

        {defaultData ? (
          <LabelValue
            label={'Equipment'}
            title={defaultData.equipmentName}
            value={<div className='input-disable'>{defaultData.equipmentName}</div>}
          />
        ) : (
          buildings.length > 0 && !assigneesAndReportersLoading ?
            <MultiSelectInput
              name={'equipments'}
              label={'*Equipment'}
              options={equipmentOptions}
              value={equipments}
              onChange={setEquipments}
            /> :
            <LabelValue
              label={'*Equipment'}
              value={
                <div className='input-disable'>
                  {assigneesAndReportersLoading ?
                    <i className="bulma-loader-mixin" aria-hidden="true"></i> :
                    <span>Please select at least 1 Building</span>}
                </div>
              }
            />
        )}

        {buildings.length > 0 && !assigneesAndReportersLoading ?
          <SelectInput
            label={'*Assignee'}
            isLoading={assigneesAndReportersLoading}
            options={assigneeOptions}
            defaultOption={'Select Assignee'}
            name={'assignee'}
            value={assignee}
            onChange={handleChangeInput(setAssignee)}
          /> :
          <LabelValue
            label={'*Assignee'}
            value={
              <div className='input-disable'>
                {assigneesAndReportersLoading ?
                  <i className="bulma-loader-mixin" aria-hidden="true"></i> :
                  <span>Please select at least 1 Building</span>
                }
              </div>
            }
          />
        }

        <SelectInput
          label={'*Status'}
          isLoading={false}
          options={statusOptions}
          defaultOption={'Select Status'}
          name={'status'}
          value={status}
          onChange={handleChangeInput(setStatus)}
        />

        <TextArea
          label={'*Summary'}
          maxLength={250}
          rows={1}
          name={'summary'}
          value={summary}
          onChange={handleChangeInput(setSummary)}
        />

        <TextArea
          label={'Description'}
          infoText={'Describe the issue'}
          maxLength={5000}
          rows={2}
          name={'description'}
          value={description}
          onChange={handleChangeInput(setDescription)}
        />

        <TextArea
          label={'Recommendations'}
          infoText={'What should be done?'}
          maxLength={5000}
          rows={2}
          name={'recommendations'}
          value={recommendations}
          onChange={handleChangeInput(setRecommendations)}
        />

        <TextArea
          label={'Actions'}
          infoText={'What has been done?'}
          maxLength={5000}
          rows={2}
          name={'actions'}
          value={actions}
          onChange={handleChangeInput(setActions)}
        />

        {equipments && equipments.length > 0 && (
          <div className="diagnostic-options-expander-area">
            <span className="diagnostic-options-expander" onClick={handleExpandDiagnosticOptions}>
              {!isDiagnosticOptionsExpanded ? 'Expand to view associated analyses' : 'Collapse analysis details'}
              <i className={!isDiagnosticOptionsExpanded ? 'flaticon-down-arrow' : 'flaticon-up-arrow'} aria-hidden="true" />
            </span>
          </div>
        )}

        {equipments && equipments.length > 0 && isDiagnosticOptionsExpanded && (
          <>
            <div className="diagnostic-options-area columns">
              <div className="diagnostic-options-header column">
                <span>Equipment</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>CMMS ID</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Analysis</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Interval</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Date</span>
              </div>
            </div>

            {diagnosticOptions.map((diagnosticOption, index) => (
              <div key={index} className="diagnostic-options-area columns">
                <div className="diagnostic-options-equipment column">
                  <span>{diagnosticOption.equipmentName}</span>
                </div>

                <div className="column">
                  <span>{diagnosticOption.cmmsReferenceId}</span>
                </div>

                <div className="column">
                  {defaultData ? (
                    <span>{defaultData.analysisName}</span>
                  ) : (
                    <SelectInput
                      noLabel={true}
                      isNotNarrow={true}
                      isLoading={false}
                      options={diagnosticOption.analysisOptions}
                      name={'analysis'}
                      value={diagnosticOption.analysis}
                      onChange={(event) => handleChangeDiagnosticInput(event, index)}
                    />
                  )}
                </div>

                <div className="column">
                  {defaultData ? (
                    <span>{diagnosticOption.interval}</span>
                  ) : (
                    <SelectInput
                      noLabel={true}
                      isNotNarrow={true}
                      isLoading={false}
                      options={intervalOptions}
                      name={'interval'}
                      value={diagnosticOption.interval}
                      onChange={(event) => handleChangeDiagnosticInput(event, index)}
                    />
                  )}
                </div>

                <div className="diagnostic-options-datepicker column">
                  {defaultData ? (
                    <span>{formatDate(diagnosticOption.date, 'd')}</span>
                  ) : (
                    <DatePickerInput
                      noLabel={true}
                      name={'date'}
                      value={diagnosticOption.date}
                      onChange={(event) => handleChangeDiagnosticInput(event, index)}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className={'modal-footer is-flex create-task-footer-container'}>
        <div className="create-task-footer-email-container">
          <div className="field is-horizontal">
            <div className="field-body">
              <input
                id="hasNotification"
                aria-label="hasNotification"
                aria-checked={hasNotification}
                type="checkbox"
                checked={hasNotification}
                className="checkbox"
                onChange={handleChangeInput(setHasNotification)}
              />
            </div>
            <div className="field-label">
              <label className="label cursor-pointer" htmlFor="hasNotification">Send Email</label>
            </div>
          </div>
          {canCreateWorkOrder && (
            <div className="field is-horizontal">
              <div className="field-body">
                <input
                  id="hasGenerateWorkOrder"
                  aria-label="hasGenerateWorkOrder"
                  aria-checked={hasGenerateWorkOrder}
                  type="checkbox"
                  checked={hasGenerateWorkOrder}
                  className="checkbox"
                  onChange={handleChangeInput(setHasGenerateWorkOrder)}
                />
              </div>
              <div className="field-label">
                <label className="label cursor-pointer" htmlFor="hasGenerateWorkOrder">
                  {workOrderCount > 1 ? `Create (${workOrderCount}) Work Orders` : 'Create Work Order'}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isValid ? '' : 'is-outlined '} ${saving ? 'is-loading' : ''}`}
            disabled={assigneesAndReportersLoading || !isValid}
            onClick={handleCreateTask}
          >
            {`Create (${taskCount}) Task${taskCount > 1 ? 's' : ''}`}
          </button>
        </div>
      </div>
    </form>
  );
};

const mapper = (array, { inputValueKey, inputLabelKey, outputValueKey, outputLabelKey }) => {
  let set = new Set([]);
  for (const e of new Set(array)) {
    set.add({ [outputValueKey]: e[inputValueKey], [outputLabelKey]: e[inputLabelKey] });
  }
  return Array.from(set);
};

const setValuesToArray = (object, fields) => {
  const result = { ...object };
  fields.forEach((field) => {
    if (!result[field]) {
      result[field] = [];
    } else if (!Array.isArray(result[field])) {
      result[field] = [result[field]];
    }
  });
  return result;
};

function mapStateToProps(state) {
  return createTaskModalSelector(state);
}

CreateTaskModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  currentWidget: PropTypes.object,
  data: PropTypes.object.isRequired,
  buildings: PropTypes.array.isRequired,
  equipmentAll: PropTypes.array.isRequired,
  assigneesAndReporters: PropTypes.array.isRequired,
  equipmentAnalysisAll: PropTypes.array.isRequired,
  taskStatuses: PropTypes.array.isRequired,
  analyses: PropTypes.array.isRequired,
  lastCreated: PropTypes.object,
  clientAll: PropTypes.array,
  buildingAll: PropTypes.array,
};

export default connect(mapStateToProps)(CreateTaskModal);
