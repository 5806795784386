import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const buildingSettings = {
  'id': 'buildingCountId',
  'kql': ' DiagnosticsDataset \n| project  BuildingName, BuildingID, ConvertedBuildingArea, ConvertedBuildingAreaUnitLabel, Latitude, Longitude\n| extend AreaLabel = strcat(round(ConvertedBuildingArea,0),\' (\',ConvertedBuildingAreaUnitLabel,\')\')',
  'datasetIDs': [widgetDatasets.BuildingsDatasetLive],
  'type': widgetType.map,
  'config': {
    'exporting': {
      'enabled': false,
    },
    'options': {
      'pitch': 0,
      'showBuildingModels': true,
      'showMapMarkers': true,
      'style': 'grayscale_light',
    },
    'sources': [
      {
        'id': 'source1',
        'cluster': false,
      },
    ],
    'layers': [
      {
        'id': 'layer1',
        'type': 'BubbleLayer',
        'source': 'source1',
        'options': {
          'color': '#144597',
          'opacity': 0,
          'strokeWidth': 0,
        },
      },
    ],
    'controls': [
      {
        'type': 'ZoomControl',
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};