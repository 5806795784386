import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../common/TextInput';
import CheckboxInput from '../common/CheckboxInput';
import SelectInput from '../common/SelectInput';
import NumberInput from '../common/NumberInput';
import LabelValue from '../common/LabelValue';
import { formatters, getBuildingFieldErrors, maxAddressCharacters, maxBuildingArea, maxBuildingNameCharacters, maxCityNameCharacters, maxInternalNotesCharacters, maxRawDataDelay, maxSasReferenceIdCharacters, maxSupportEmailOverrideCharacters, minBuildingArea, minRawDataDelay } from '../buildings/buildingModal/utils';
import DatePickerInput from '../common/DatePickerInput';
import TextArea from '../common/TextArea';
import { mapper } from '../../utils';
import { useForm } from '../../utils/hooks';
import { useGetLookup } from '../buildings/buildingModal/hooks';
import { updateBuilding } from '../../actions/buildingModuleActions';
import { userResources } from '../../enums/resources';
import { toastr } from 'react-redux-toastr';

const unitSystemOptions = [
  {
    value: true,
    text: 'SI',
  },
  {
    value: false,
    text: 'IP',
  },
];

const EditBuildingModal = ({ culture, dataItem, saveCallback, cancelCallback }) => {
  const { values, setValues, onInputChange } = useForm({
    buildingName: dataItem.buildingName,
    isActive: dataItem.isActive,
    isVisible: dataItem.isVisible,
    address: dataItem.address,
    city: dataItem.city,
    countryId: dataItem.countryId,
    stateId: dataItem.stateId,
    zip: dataItem.zip,
    latitude: dataItem.latitude,
    longitude: dataItem.longitude,
    timeZone: dataItem.timeZone,
    lcid: dataItem.lcid,
    unitSystem: dataItem.unitSystem,
    area: dataItem.area,
    areaUnitLabel: dataItem.areaUnitLabel,
    buildingClassId: dataItem.buildingClassId,
    buildingTypeId: dataItem.buildingTypeId,
    dateCreated: dataItem.dateCreated,
    buildingConfigurationCompleteDate: dataItem.buildingConfigurationCompleteDate ? new Date(dataItem.buildingConfigurationCompleteDate) : null,
    supportEmailOverride: dataItem.supportEmailOverride,
    rawDataDelay: dataItem.rawDataDelay,
    sasReferenceId: dataItem.sasReferenceId,
    internalNotes: dataItem.internalNotes,
  });

  const dispatch = useDispatch();
  const resources = useSelector((state) => state.user.resources);
  const dashboardFilters = useSelector((state) => state.dashboardFilters);
  const buildingSaving = useSelector((state) => state.buildingModule.buildingSaving);
  const lookup = useGetLookup(values.countryId);
  const [fieldErrors, setFieldErrors] = useState();

  const hasSystemAdminReadPermission = useMemo(
    () => resources.includes(userResources.SystemAdministration),
    [resources],
  );

  const countryOptions = useMemo(
    () => mapper(
      lookup.countries, {
        inputValueKey: 'countryId',
        inputLabelKey: 'countryName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [lookup.countries],
  );

  const stateOptions = useMemo(
    () => mapper(
      lookup.states, {
        inputValueKey: 'stateId',
        inputLabelKey: 'stateName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [lookup.states],
  );

  const timezoneOptions = useMemo(
    () => mapper(
      lookup.timezones, {
        inputValueKey: 'id',
        inputLabelKey: 'displayName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [lookup.timezones],
  );

  const cultureOptions = useMemo(
    () => mapper(
      lookup.cultures, {
        inputValueKey: 'lcid',
        inputLabelKey: 'cultureName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [lookup.cultures],
  );

  const buildingClassOptions = useMemo(
    () => mapper(
      dashboardFilters.buildingClassAll, {
        inputValueKey: 'buildingClassId',
        inputLabelKey: 'buildingClassName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [dashboardFilters.buildingClassAll],
  );

  const buildingTypeOptions = useMemo(
    () => mapper(
      dashboardFilters.buildingTypeAll.filter(
        (e) => e.buildingClassId === parseInt(values.buildingClassId),
      ), {
        inputValueKey: 'buildingTypeId',
        inputLabelKey: 'buildingTypeName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [dashboardFilters.buildingTypeAll, values.buildingClassId],
  );


  const handleCountryChange = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      countryId: value,
      stateId: null,
    }));
  };

  const handleUnitSystemChange = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      unitSystem: value,
      areaUnitLabel: value === 'true' ? 'sqm' : 'sqft',
    }));
  };

  const handleBuildingClassChange = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      buildingClassId: value,
      buildingTypeId: null,
    }));
  };

  const numberChangeHandler = (min, max) => (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => {
      const newValue = value > max
        ? prevValues[name]
        : value < min
          ? min
          : value;
      return {
        ...prevValues,
        [name]: newValue,
      };
    });
  };

  const handleSubmit = () => {
    const selectedCountry = lookup.countries.find(
      (country) => country.countryId === parseInt(values.countryId),
    );

    const errors = getBuildingFieldErrors(values, { stateOptions, selectedCountry });
    setFieldErrors(errors);
    if (errors) return;

    const payload = {
      BID: dataItem.bid,
      UnitID: dataItem.unitId,
      IsActive: values.isActive,
      IsVisible: values.isVisible,
      Area: parseFloat(values.area),
      BuildingName: values.buildingName,
      BuildingTypeId: parseInt(values.buildingTypeId),
      Address: values.address,
      City: values.city,
      LCID: parseInt(values.lcid),
      CountryAlpha2Code: selectedCountry.alpha2Code,
      CountryID: selectedCountry.countryId,
      Latitude: parseFloat(values.latitude),
      Longitude: parseFloat(values.longitude),
      StateID: parseInt(values.stateId),
      SupportEmailOverride: values.supportEmailOverride,
      RawDataDelay: parseFloat(values.rawDataDelay),
      TimeZone: values.timeZone,
      Zip: values.zip,
      SASReferenceID: values.sasReferenceId,
      InternalNotes: values.internalNotes,
      BuildingConfigurationCompleteDate: typeof values.buildingConfigurationCompleteDate === 'string'
        ? values.buildingConfigurationCompleteDate
        : values.buildingConfigurationCompleteDate?.toISOString() ?? null,
      UnitSystem: typeof values.unitSystem === 'string'
        ? values.unitSystem === 'true'
        : values.unitSystem,
    };

    dispatch(updateBuilding(payload))
      .then((response) => {
        toastr.success('Successfully saved', 'Building have been successfully saved.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        saveCallback(response);
      }).catch(() => {});
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className="box field-body--grow-4" name="editModal">
      <div className="panel-block is-wrapped filter no-border modal-header px-0">
        <h1 className="title mb-4">
          Edit {dataItem.buildingName}
        </h1>
      </div>

      <div className="box modal-main" style={{ minHeight: 120 }}>
        <TextInput
          label="*Building Name:"
          maxLength={maxBuildingNameCharacters}
          name="buildingName"
          value={values.buildingName}
          onChange={onInputChange}
          errors={fieldErrors?.buildingName && [fieldErrors.buildingName]}
        />

        <LabelValue
          label="Organization Name:"
          value={dataItem.unitName}
        />

        <CheckboxInput
          label="*Active:"
          name="isActive"
          checked={values.isActive}
          onChange={onInputChange}
          errors={fieldErrors?.isActive && [fieldErrors.isActive]}
        />

        <CheckboxInput
          label="*Visible:"
          name="isVisible"
          checked={values.isVisible}
          onChange={onInputChange}
          errors={fieldErrors?.isVisible && [fieldErrors.isVisible]}
        />

        <TextInput
          label="*City:"
          maxLength={maxCityNameCharacters}
          name="city"
          value={values.city}
          onChange={onInputChange}
          errors={fieldErrors?.city && [fieldErrors.city]}
        />

        <TextInput
          label="*Address:"
          maxLength={maxAddressCharacters}
          name="address"
          value={values.address}
          onChange={onInputChange}
          errors={fieldErrors?.address && [fieldErrors.address]}
        />

        <SelectInput
          label="*Country:"
          name="countryId"
          defaultOption="Select Country"
          options={countryOptions}
          value={values.countryId}
          onChange={handleCountryChange}
          errors={fieldErrors?.countryId && [fieldErrors.countryId]}
        />

        <SelectInput
          label="State:"
          info={['Required based on Country']}
          name="stateId"
          defaultOption="Select State"
          options={stateOptions}
          value={values.stateId}
          onChange={onInputChange}
          errors={fieldErrors?.stateId && [fieldErrors.stateId]}
        />

        <TextInput
          fullWidth={false}
          label="Zip Code:"
          info={['Required based on Country']}
          name="zip"
          value={values.zip}
          onChange={onInputChange}
          errors={fieldErrors?.zip && [fieldErrors.zip]}
        />

        <NumberInput
          label="Latitude:"
          format="##.##########"
          name="latitude"
          value={values.latitude}
          onChange={onInputChange}
        />

        <NumberInput
          label="Longitude:"
          format="##.##########"
          name="longitude"
          value={values.longitude}
          onChange={onInputChange}
        />

        <SelectInput
          label="*Time Zone:"
          name="timeZone"
          defaultOption="Select Time Zone"
          options={timezoneOptions}
          value={values.timeZone}
          onChange={onInputChange}
          errors={fieldErrors?.timeZone && [fieldErrors.timeZone]}
        />

        <SelectInput
          fullWidth={false}
          label="*Culture:"
          name="lcid"
          defaultOption="Select Culture"
          options={cultureOptions}
          value={values.lcid}
          onChange={onInputChange}
          errors={fieldErrors?.lcid && [fieldErrors.lcid]}
        />

        <SelectInput
          fullWidth={false}
          label="*Unit System:"
          name="unitSystem"
          defaultOption="Select Unit System"
          options={unitSystemOptions}
          value={values.unitSystem}
          onChange={handleUnitSystemChange}
          errors={fieldErrors?.unitSystem && [fieldErrors.unitSystem]}
        />

        <NumberInput
          label="*Area:"
          format="#########"
          unitLabel={values.areaUnitLabel}
          min={minBuildingArea}
          max={maxBuildingArea}
          name="area"
          value={values.area}
          onChange={numberChangeHandler(minBuildingArea, maxBuildingArea)}
          errors={fieldErrors?.area && [fieldErrors.area]}
        />

        <SelectInput
          label="*Building Class:"
          name="buildingClassId"
          defaultOption="Select Building Class"
          options={buildingClassOptions}
          value={values.buildingClassId}
          onChange={handleBuildingClassChange}
          errors={fieldErrors?.buildingClassId && [fieldErrors.buildingClassId]}
        />

        <SelectInput
          label="*Building Type:"
          name="buildingTypeId"
          defaultOption="Select Building Type"
          options={buildingTypeOptions}
          value={values.buildingTypeId}
          onChange={onInputChange}
          errors={fieldErrors?.buildingTypeId && [fieldErrors.buildingTypeId]}
        />

        <LabelValue
          label="Date Created:"
          value={formatters.date({ details: dataItem, field: 'dateCreated' }, { culture })}
        />

        <DatePickerInput
          label="Configuration Complete Date:"
          min={new Date(dataItem.dateCreated)}
          name="buildingConfigurationCompleteDate"
          value={values.buildingConfigurationCompleteDate}
          onChange={onInputChange}
          errors={fieldErrors?.buildingConfigurationCompleteDate && [fieldErrors.buildingConfigurationCompleteDate]}
        />

        <TextInput
          label="Support Request Email Override:"
          maxLength={maxSupportEmailOverrideCharacters}
          name="supportEmailOverride"
          value={values.supportEmailOverride}
          onChange={onInputChange}
          errors={fieldErrors?.supportEmailOverride && [fieldErrors.supportEmailOverride]}
        />

        <NumberInput
          label="*Raw Data Delay (Hours):"
          format="####"
          min={minRawDataDelay}
          max={maxRawDataDelay}
          name="rawDataDelay"
          value={values.rawDataDelay}
          onChange={numberChangeHandler(minRawDataDelay, maxRawDataDelay)}
          errors={fieldErrors?.rawDataDelay && [fieldErrors.rawDataDelay]}
        />

        <TextInput
          label="SAS Reference ID:"
          maxLength={maxSasReferenceIdCharacters}
          name="sasReferenceId"
          value={values.sasReferenceId}
          onChange={onInputChange}
          errors={fieldErrors?.sasReferenceId && [fieldErrors.sasReferenceId]}
        />

        {hasSystemAdminReadPermission && (
          <TextArea
            label="Internal Notes:"
            maxLength={maxInternalNotesCharacters}
            name="internalNotes"
            value={values.internalNotes}
            onChange={onInputChange}
            errors={fieldErrors?.internalNotes && [fieldErrors.internalNotes]}
          />
        )}
      </div>

      <div className="modal-footer d-flex justify-content-center">
        <div className="buttons">
          <button
            type="submit"
            className={classNames('button is-info is-medium', { 'is-loading': buildingSaving })}
            disabled={buildingSaving}
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="button is-info is-outlined is-medium"
            disabled={buildingSaving}
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
};

EditBuildingModal.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default EditBuildingModal;
