import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const equipmentSettings = {
  'id': 'equipmentCountId',
  'queryString': '?$apply=filter(IsActive eq true and IsVisible eq true)/groupby((EquipmentClassName, EquipmentClassID), aggregate($count as equipCount))&$orderby=equipCount desc',
  'filterOverrides': 'EquipmentClassID=3,4,5,6,9,13,21,36,37,38,49,51',
  'datasetIDs': [widgetDatasets.EquipmentDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'bar',
    },
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'plotOptions': {
      'series': {
        'borderRadius': 3,
      },
    },
    'credits': 'false',
    'title': {
      'text': null,
    },
    'legend': {
      'enabled': false,
    },
    'datalabels': {
      'enabled:': 'true',
      'softConnector': 'false',
    },
    'xAxis': {
      'type': 'category',
      'gridLineWidth': 0,
      'tickWidth': 0,
      'lineWidth': 0,
    },
    'yAxis': {
      'title': {
        'enabled': false,
      },
      'labels': {
        'enabled': false,
      },
      'gridLineWidth': 0,
      'tickWidth': 0,
      'lineWidth': 0,
    },
    'series': [],
    'dataMapper': [
      {
        'name': {
          'type': 'string',
          'value': 'Equipment Class Count',
        },
        'innerSize': '65%',
        'data': {
          'name': {
            'type': 'field',
            'value': 'equipmentClassName',
          },
          'y': {
            'type': 'field',
            'value': 'equipCount',
          },
        },
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const equipmentCount = {
  'id': 'equipmentCountId',
  'queryString': '?$apply=filter(IsActive eq true)/aggregate(EquipmentID with countdistinct as totalEquipment)',
  'filterOverrides': 'EquipmentClassID=3,4,5,6,9,13,21,36,37,38,49,51',
  'datasetIDs': [widgetDatasets.EquipmentDataset],
  'type': widgetType.html,
  'config': '',
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};