import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import BaseGrid from '../../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../../hooks/useElementWidthListener';
import useGridDataState from '../../../../hooks/useGridDataState';
import { setPercentage } from '../../../../utils';
import { updateItem, updateSelectedDropdownItem } from '../utils';
import { getDiagnosticReportEquipmentPoints, getPointTypeView, updatePoint } from '../../../../actions/diagnosticsModuleActions';
import DataPointsDropdown from './DataPointsDropdown';
import MyCommandCell from '../EquipmentVariables/MyCommandCell';

const editField = 'inEdit';

const DataPointsGrid = ({ unitid, bid, eid, data: dataProp, canEdit, loading }) => {
  const dispatch = useDispatch();
  const lcid = useSelector((state) => state.user.lcid);
  const pointTypeViewList = useSelector((state) => state.diagnosticsModule.pointTypeViewList);
  const pointTypeViewListLoading = useSelector((state) => state.diagnosticsModule.pointTypeViewListLoading);
  const gridWidth = useElementWidthListener('.data-points-grid .k-widget.k-grid');
  const { dataState, setDataState, onDataStateChange } = useGridDataState(dataProp, config);
  const [statuses, setStatuses] = useState({ updating: false });
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);
  const [listFetched, setListFetched] = useState(false);

  const hasLoadingStatus = Object.values(statuses).some((status) => status);
  const data = dataState.result.data;

  useEffect(() => {
    if (!listFetched) {
      dispatch(getPointTypeView({ bid, eid }));
      setListFetched(true);
    }
  }, [pointTypeViewList]);

  const refreshList = () => dispatch(getDiagnosticReportEquipmentPoints({ bid, eid, refreshDate: Date.now() }));

  const setData = (newData, configOverride) => {
    setDataState((prev) => ({
      dataState: { ...prev.dataState, ...configOverride },
      result: process(newData, { ...prev.dataState, ...configOverride }),
    }));
  };

  const onItemChange = (event) => {
    event.dataItem[event.field] = event.value;
    const newData = updateItem(event.dataItem, data, 'pid');
    setData(newData, { sort: [] });
  };

  const onSelectChange = (selectedItem, dataItem) => {
    const newItem = {
      ...dataItem,
      ...selectedItem,
      isNew: false,
      [editField]: true,
    };
    const newData = updateSelectedDropdownItem(newItem, data, 'pid');
    setData(newData, { sort: [] });
    setSelectedDropdownItem(selectedItem);
  };

  const save = async (dataItem) => {
    setStatuses((prev) => ({ ...prev, updating: true }));
    await dispatch(
      updatePoint({ ...dataItem, unitid, bid, eid, lcid }),
    ).finally(() => {
      setStatuses((prev) => ({ ...prev, updating: false }));
    });
    const newData = await refreshList();
    setData(newData, config);
    setSelectedDropdownItem(null);
  };

  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
    setSelectedDropdownItem(null);
  };

  const cancel = () => {
    const newData = dataProp
      .filter((e) => !e.isNew)
      .map((e) => ({ ...e, isNew: false, [editField]: false }));
    setData(newData, config);
    setSelectedDropdownItem(null);
  };
  
  const enterEdit = (dataItem) => {
    setData(
      data.map((item) => ({
        ...item,
        [editField]: item.pid === dataItem.pid,
      })),
    );
    const defaultDropdownItem = pointTypeViewList.find(
      (item) => item.pointTypeId === dataItem.pointTypeId,
    );
    setSelectedDropdownItem(defaultDropdownItem);
  };

  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      valueKey="pointTypeId"
      edit={enterEdit}
      discard={discard}
      save={save}
      cancel={cancel}
      editField={editField}
      loading={loading || hasLoadingStatus}
      disableSaveCallback={(dataItem) => !dataItem.pointTypeId || !dataItem.pointName || hasLoadingStatus}
    />
  );

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="data-points-grid"
      sortable={{ allowUnsort: true, mode: 'multiple' }}
      editField={editField}
      data={dataState.result}
      {...dataState.dataState}
      onItemChange={onItemChange}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          <Cell
            {...props}
            edit={enterEdit}
            discard={discard}
            save={save}
            cancel={cancel}
            editField={editField}
            selectedItem={selectedDropdownItem}
            onSelectChange={onSelectChange}
            pointTypesList={pointTypeViewList}
            pointTypesListLoading={pointTypeViewListLoading}
          />
        );

        return (
          <GridColumn
            {...column}
            key={column.field} 
            field={column.field}
            title={column.title}
            editor={column.editor}
            cell={Cell ? CustomCell : undefined}
            editable={canEdit && column.editable}
            width={setPercentage(gridWidth, canEdit ? column.width: column.width + 3)}
          />
        );
      })}

      {canEdit && (
        <GridColumn
          cell={CommandCell}
          width={setPercentage(gridWidth, 10)}
        />
      )}
    </BaseGrid>
  );
};

const config = {
  sort: [{ field: 'pointName', dir: 'asc' }],
};

const renderers = {
  pointType: DataPointsDropdown,
};

const columns = [
  {
    field: 'pointName',
    title: 'Data Points',
    width: 35,
    editable: true,
    editor: 'text',
  },
  {
    field: 'pointType',
    title: 'Point Type',
    width: 35,
    editable: true,
    cell: renderers.pointType,
  },
  {
    field: 'unit',
    title: 'Unit',
    width: 20,
    editable: false,
  },
];

DataPointsGrid.propTypes = {
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  canEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DataPointsGrid;
