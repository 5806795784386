import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import * as api from '../api/api';
import * as types from './actionTypes';
import * as equipmentHelpers from '../actionHelpers/equipmentHelpers';
import * as pointHelpers from '../actionHelpers/pointHelpers';
import { getAnalysisIds } from '../actionHelpers/analysisHelpers';
import { getAllBuildingIds, getAvailableBuildingIds } from '../actionHelpers/buildingHelpers';
import handleErrorResponses from './errorActions';
import { setCalculatingFilters } from './commonActions';
import toMap from '../utils/toMap';
import { getAppliedFilters, hasFilterValue } from '../actionHelpers/commonHelpers';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { dependentFilterGroups, filterFields, lookupFilterGroups } from '../enums/filters';

// dashboard filters loaded
export const setDashboardFiltersLoaded = () => ({
  type: types.SET_DASHBOARD_FILTERS_LOADED,
  payload: true,
});

// widget filters loaded
export const setWidgetFiltersLoaded = (payload) => ({
  type: types.SET_WIDGET_FILTERS_LOADED,
  payload,
});

// clear dashboard filters
export const clearDashboardFilters = () => ({
  type: types.CLEAR_DASHBOARD_FILTERS,
});

// set filter open
export const setFilterOpen = (payload) => ({
  type: types.SET_FILTER_OPEN,
  payload,
});

// equipment classes
export const getEquipmentClassesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_CLASSES_SUCCESS,
  list,
});
export const getEquipmentLookupClassesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_LOOKUP_CLASSES_SUCCESS,
  list,
});
export const getEquipmentClasses = (payload) => (dispatch) => api.getEquipmentClasses(payload).then((list) => {
  dispatch(getEquipmentClassesSuccess(list));
}, (error) => {
  handleErrorResponses(error);
  throw (error);
});

// equipment types
export const getEquipmentTypesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_TYPES_SUCCESS,
  list,
});
export const getEquipmentLookupTypesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_LOOKUP_TYPES_SUCCESS,
  list,
});
export const getEquipmentTypes = (payload) => (dispatch) => api.getEquipmentTypes(payload).then((list) => {
  dispatch(getEquipmentTypesSuccess(list));
}, (error) => {
  handleErrorResponses(error);
  throw (error);
});

// configuration status options
export const getEquipmentLookupConfigurationStatusesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_LOOKUP_CONFIGURATION_STATUSES_SUCCESS,
  list,
});

// equipments
export const getEquipmentsSuccess = (list) => ({
  type: types.GET_EQUIPMENTS_SUCCESS,
  list,
});
export const getEquipmentLookupEquipmentsSuccess = (list) => ({
  type: types.GET_EQUIPMENT_LOOKUP_EQUIPMENTS_SUCCESS,
  list,
});

// points related
export const getPointClassesSuccess = (list) => ({
  type: types.GET_POINT_CLASSES_SUCCESS,
  list,
});
export const getPointLookupClassesSuccess = (list) => ({
  type: types.GET_POINT_LOOKUP_CLASSES_SUCCESS,
  list,
});
export const getPointTypesSuccess = (list) => ({
  type: types.GET_POINT_TYPES_SUCCESS,
  list,
});
export const getPointLookupTypesSuccess = (list) => ({
  type: types.GET_POINT_LOOKUP_TYPES_SUCCESS,
  list,
});
export const getPointsSuccess = (list) => ({
  type: types.GET_POINTS_SUCCESS,
  list,
});
export const getVPointsSuccess = (list) => ({
  type: types.GET_V_POINTS_SUCCESS,
  list,
});

export const getEquipmentsGroupFilters = ({ selectedBIDs, equipmentFilters, jsonFilters, dashboardFilters = {}, skipGlobalFilters, refetchLookups }) => (dispatch, getState) => {
  const state = getState();
  const client = dashboardFilters.client || getState().dashboardFilters.client || [];
  const {
    classes: lookupClasses = [], types: lookupTypes = [], equipments: lookupEquipments = [], equipmentConfigurationStatuses = [],
    classesLoaded, typesLoaded, equipmentConfigurationStatusesLoaded,
  } = state.equipmentLookup;
  const hasLookupClasses = classesLoaded;
  const hasLookupTypes = typesLoaded;
  const hasLookupEquipmentConfigurationStatuses = equipmentConfigurationStatusesLoaded;

  const orgFilter = jsonFilters.find(({ key }) => key === 'client');
  const selectedOrgIds = hasFilterValue(orgFilter) ? orgFilter.value : client.map((e) => e.unitId);

  const getClassRequest = () => hasLookupClasses && !refetchLookups ? lookupClasses : api.getEquipmentClasses();
  const getTypeRequest = () => hasLookupTypes && !refetchLookups ? lookupTypes : api.getEquipmentTypes();
  const getEquipmentRequest = () => skipGlobalFilters ? lookupEquipments : api.getEquipments({ UnitID: selectedOrgIds });
  const getEquipmentConfigurationStatusesRequest = () => hasLookupEquipmentConfigurationStatuses ? equipmentConfigurationStatuses : api.getEquipmentConfigurationStatuses();

  const promises = [
    getClassRequest(),
    getTypeRequest(),
    getEquipmentRequest(),
    getEquipmentConfigurationStatusesRequest(),
  ];

  return Promise.all(promises).then(
    ([classList, typeList, equipmentList, configurationStatusList]) => {
      const sortedClassList = sortBy(classList, (ec) => ec.equipmentClassName.toLowerCase());
      const sortedTypeList = sortBy(typeList, (et) => et.equipmentTypeName.toLowerCase());
      const sortedEquipmentList = sortBy(equipmentList, (e) => e.equipmentName.toLowerCase());

      const availableBIDs = getAvailableBuildingIds(getState);
      const selectedBuildingsOnly = Boolean(selectedBIDs.length);
      const buildingIDsToMap = selectedBuildingsOnly ? toMap(selectedBIDs) : toMap(availableBIDs);
      let buildingEquipments = sortedEquipmentList.filter(({ bid }) => buildingIDsToMap.has(bid));

      buildingEquipments = equipmentHelpers.filterByEquipmentVisibility(buildingEquipments, equipmentFilters);
      buildingEquipments = equipmentHelpers.filterByEquipmentActive(buildingEquipments, equipmentFilters);
      buildingEquipments = equipmentHelpers.filterByConfigurationStatus(buildingEquipments, equipmentFilters);

      const equipmentClasses = sortBy(
        uniqBy(buildingEquipments, 'equipmentClassId')
          .map(({ equipmentClassId, bid }) => ({
            id: equipmentClassId,
            name: equipmentHelpers.getEquipmentClassName(sortedClassList, equipmentClassId),
            buildingId: bid,
          })),
        (ec) => ec.name.toLowerCase(),
      );

      const equipmentTypes = sortBy(
        uniqBy(buildingEquipments, 'equipmentTypeId')
          .map(({ equipmentTypeId, bid, equipmentClassId }) => ({
            id: equipmentTypeId,
            name: equipmentHelpers.getEquipmentTypeName(sortedTypeList, equipmentTypeId),
            buildingId: bid,
            equipmentClassId,
          })),
        (et) => et.name.toLowerCase(),
      );

      const equipments = sortBy(buildingEquipments
        .map(({ eid, bid, equipmentName, ...rest }) => ({
          ...rest,
          id: eid,
          buildingId: bid,
          name: equipmentName,
        })),
      (e) => e.name.toLowerCase(),
      );

      const dropdownValues = {
        equipmentClass: equipmentClasses,
        equipmentClassAll: equipmentClasses,
        equipmentType: equipmentTypes,
        equipmentTypeAll: equipmentTypes,
        equipment: equipments,
        equipmentAll: equipments,
        equipmentLoaded: true,
      };

      const newJsonFilters = JSON.parse(JSON.stringify(equipmentFilters));

      // save to redux for future use
      if (!hasLookupClasses) {
        dispatch(getEquipmentLookupClassesSuccess(sortedClassList));
      }

      // save to redux for future use
      if (!hasLookupTypes) {
        dispatch(getEquipmentLookupTypesSuccess(sortedTypeList));
      }

      // save to redux for future use
      if (!hasLookupEquipmentConfigurationStatuses) {
        dispatch(getEquipmentLookupConfigurationStatusesSuccess(configurationStatusList));
      }

      // save to redux for future use
      if (!skipGlobalFilters) {
        dispatch(getEquipmentLookupEquipmentsSuccess(sortedEquipmentList));
      }

      // cascade equipment filter changes
      equipmentFilters.forEach((eachFilter) => {
        equipmentHelpers.getEquipmentsGroupFiltersDependencies(
          eachFilter,
          dropdownValues,
          newJsonFilters,
        );
      });

      // cascade effects to applied filters
      equipmentHelpers.getEquipmentsGroupFiltersValues(
        dropdownValues,
        newJsonFilters,
      );

      return {
        dropdownValues,
        jsonFilters: newJsonFilters,
      };
    }).catch((error) => {
    handleErrorResponses(error);
    throw (error);
  });
};

export function getDiagnosticsGroupFilters({ jsonFilters, dashboardFilters = {}, skipGlobalFilters }) {
  return async (dispatch, getState) => {
    const state = getState();
    const { analysisAll = [], equipmentAnalysisAll = [] } = await dispatch(getAnalysesDropdownValues({ jsonFilters, dashboardFilters, skipGlobalFilters }));

    const equipment = dashboardFilters.equipment || state.dashboardFilters.equipment || [];
    const { value: equipmentFilterValues = [] } = jsonFilters.find(({ key }) => key === 'equipment') || {};
    const { value: analysisFilterValues = [] } = jsonFilters.find(({ key }) => key === 'analysis') || {};
    const isEquipmentFilterApplied = Boolean(equipmentFilterValues.length);
    const isAnalysisFilterApplied = Boolean(analysisFilterValues.length);

    let filteredEquipmentAnalyses = [];

    if (isEquipmentFilterApplied) {
      const equipmentFilterValuesToMap = toMap(equipmentFilterValues);
      filteredEquipmentAnalyses = equipmentAnalysisAll.filter((ea) => equipmentFilterValuesToMap.has(ea.eid));
    } else {
      const equipmentToMap = toMap(equipment, 'id');
      filteredEquipmentAnalyses = equipmentAnalysisAll.filter((ea) => equipmentToMap.has(ea.eid));
    }

    const filteredUniqAnalyses = new Set(getAnalysisIds(filteredEquipmentAnalyses));
    const filteredUniqAnalysesToMap = toMap(filteredUniqAnalyses);
    const filteredAnalysis = analysisAll.filter(({ aid }) => filteredUniqAnalysesToMap.has(aid));

    const dropdownValues = {
      analysis: filteredAnalysis,
      analysisAll,
      equipmentAnalysisAll,
    };
    let newJsonFilters = [];

    if (isAnalysisFilterApplied) {
      const newAnalysisAppliedFilters = analysisFilterValues.filter((analysisId) => filteredAnalysis.some(({ aid }) => aid === analysisId));
      newJsonFilters = [{
        key: 'analysis',
        value: newAnalysisAppliedFilters,
      }];
    }

    return {
      dropdownValues,
      jsonFilters: newJsonFilters,
    };
  };
}

export const getPointsGroupFilters = ({ selectedBIDs, dashboardFilters = {}, jsonFilters }) => async (dispatch, getState) => {
  dispatch(setCalculatingFilters({ pointClass: true, pointType: true, point: true }));

  const state = getState();
  const availableBIDs = getAvailableBuildingIds(getState);
  const pointAll = dashboardFilters.pointAll || getState().dashboardFilters.pointAll || [];
  const pointAllParams = dashboardFilters.pointAllParams || getState().dashboardFilters.pointAllParams || {};

  const equipmentFilters = jsonFilters.filter(({ key, value }) => value && lookupFilterGroups.equipmentGroup.includes(key));
  const pointFilters = jsonFilters.filter(({ key, value }) => value && ['pointClass', 'pointType'].includes(key));
  const dataSourceFilter = jsonFilters.filter(({ key, value }) => value && ['dataSource'].includes(key));

  const { classes: lookupClasses = [], types: lookupTypes = [], classesLoaded, typesLoaded } = state.pointLookup;
  const hasLookupClasses = classesLoaded;
  const hasLookupTypes = typesLoaded;

  const getClassRequest = () => hasLookupClasses ? lookupClasses : api.getPointClasses();
  const getTypeRequest = () => hasLookupTypes ? lookupTypes : api.getPointTypes();

  const promises = [
    getClassRequest(),
    getTypeRequest(),
  ];

  return Promise.all(promises).then(
    ([classList, typeList]) => {
      const sortedClassList = classList;
      const sortedTypeList = typeList;
      const pointClasses = classList
        .map(({ pointClassId, pointClassName }) => ({
          id: pointClassId,
          name: pointClassName,
        }));

      let pointClassIds = pointClasses.map((e) => e.id);
      const appliedPointClasses = pointFilters.find((e) => e.key === 'pointClass');
      if (appliedPointClasses && appliedPointClasses.value && appliedPointClasses.value.length) {
        pointClassIds = appliedPointClasses.value;
      }

      const pointTypes = typeList
        .filter(({ pointClassId }) => pointClassIds.includes(pointClassId))
        .map(({ pointTypeId, pointTypeName, pointClassId }) => ({
          id: pointTypeId,
          name: pointTypeName,
          pointClassId,
        }));

      const dropdownValues = {
        pointAll,
        pointAllParams,
        pointClassAll: pointClasses,
        pointClass: pointClasses,
        pointTypeAll: pointTypes,
        pointType: pointTypes,
      };

      const newJsonFilters = jsonFilters.filter(
        ({ key, value }) => value && dependentFilterGroups.dataGroup.includes(key),
      );

      // save to redux for future use
      if (!hasLookupClasses) {
        dispatch(getPointLookupClassesSuccess(sortedClassList));
      }

      // save to redux for future use
      if (!hasLookupTypes) {
        dispatch(getPointLookupTypesSuccess(sortedTypeList));
      }

      // cascade new point type filter values
      pointHelpers.cascadeFetchPointTypes(dropdownValues, newJsonFilters);

      return {
        dropdownValues,
        jsonFilters: newJsonFilters,
      };
    }).then(async ({ dropdownValues: dropdownValuesUpdated, jsonFilters: jsonFiltersUpdated }) => {
    const params = { BID: selectedBIDs.length ? selectedBIDs : availableBIDs };
    const appliedEquipmentClasses = equipmentFilters.find((e) => e.key === 'equipmentClass');
    const appliedEquipmentTypes = equipmentFilters.find((e) => e.key === 'equipmentType');
    const appliedEquipments = equipmentFilters.find((e) => e.key === 'equipment');
    if (appliedEquipmentClasses && appliedEquipmentClasses.value && appliedEquipmentClasses.value.length) {
      params.EquipmentClassID = appliedEquipmentClasses.value;
    }
    if (appliedEquipmentTypes && appliedEquipmentTypes.value && appliedEquipmentTypes.value.length) {
      params.EquipmentTypeID = appliedEquipmentTypes.value;
    }
    if (appliedEquipments && appliedEquipments.value && appliedEquipments.value.length) {
      params.EID = appliedEquipments.value;
    }

    const appliedPointClasses = jsonFiltersUpdated.find((e) => e.key === 'pointClass');
    const appliedPointTypes = jsonFiltersUpdated.find((e) => e.key === 'pointType');
    if (appliedPointClasses && appliedPointClasses.value && appliedPointClasses.value.length) {
      params.PointClassID = appliedPointClasses.value;
    }
    if (appliedPointTypes && appliedPointTypes.value && appliedPointTypes.value.length) {
      params.PointTypeID = appliedPointTypes.value;
    }

    const appliedDataSources = dataSourceFilter.find((e) => e.key === 'dataSource');
    if (appliedDataSources && appliedDataSources.value && appliedDataSources.value.length) {
      params.DSID = appliedDataSources.value;
    }
    const areParamsEqual = JSON.stringify(params) === JSON.stringify(dropdownValuesUpdated.pointAllParams);
    const fetchPoints = () => api.getPoints(params)
      .then((pointList) => pointList.map(({ pid, pointName, isActive }) => ({
        id: pid,
        name: pointName,
        isActive,
      })));

    const allPoints = areParamsEqual ? dropdownValuesUpdated.pointAll : await fetchPoints();
    const points = pointHelpers.filterByPointActive(allPoints, jsonFiltersUpdated);

    const dropdownValues = {
      ...dropdownValuesUpdated,
      pointAllParams: params,
      pointAll: allPoints,
      point: points,
    };

    const newJsonFilters = [...jsonFiltersUpdated];

    // cascade new point filter values
    pointHelpers.cascadeFetchPoints(dropdownValues, newJsonFilters);

    return {
      dropdownValues,
      jsonFilters: newJsonFilters,
    };
  })
    .catch((error) => {
      handleErrorResponses(error);
      throw (error);
    }).finally(() => {
      dispatch(setCalculatingFilters({ pointClass: false, pointType: false, point: false }));
    });
};

export const getVPointsGroupFilters = ({ selectedBIDs, jsonFilters }) => async (_, getState) => Promise.resolve(true).then(async () => {
  const availableBIDs = getAvailableBuildingIds(getState);

  const equipmentFilters = jsonFilters.filter(({ key, value }) => value && lookupFilterGroups.equipmentGroup.includes(key));

  const params = { BID: selectedBIDs.length ? selectedBIDs : availableBIDs };
  const appliedEquipmentClasses = equipmentFilters.find((e) => e.key === 'equipmentClass');
  const appliedEquipmentTypes = equipmentFilters.find((e) => e.key === 'equipmentType');
  const appliedEquipments = equipmentFilters.find((e) => e.key === 'equipment');
  if (appliedEquipmentClasses && appliedEquipmentClasses.value && appliedEquipmentClasses.value.length) {
    params.EquipmentClassID = appliedEquipmentClasses.value;
  }
  if (appliedEquipmentTypes && appliedEquipmentTypes.value && appliedEquipmentTypes.value.length) {
    params.EquipmentTypeID = appliedEquipmentTypes.value;
  }
  if (appliedEquipments && appliedEquipments.value && appliedEquipments.value.length) {
    params.EID = appliedEquipments.value;
  }

  const appliedPointClasses = jsonFilters.find((e) => e.key === 'pointClass');
  const appliedPointTypes = jsonFilters.find((e) => e.key === 'pointType');
  if (appliedPointClasses && appliedPointClasses.value && appliedPointClasses.value.length) {
    params.PointClassID = appliedPointClasses.value;
  }
  if (appliedPointTypes && appliedPointTypes.value && appliedPointTypes.value.length) {
    params.PointTypeID = appliedPointTypes.value;
  }

  const vPoints = await api.getVPoints(params)
    .then((list) => list.map(({ vpid, vPointName }) => ({
      id: vpid,
      name: vPointName,
    })));

  const dropdownValues = {
    vPointAll: vPoints,
    vPoint: vPoints,
  };

  const newJsonFilters = [];

  // cascade new vpoint filter values
  pointHelpers.cascadeFetchVPoints(dropdownValues, newJsonFilters);

  return {
    dropdownValues,
    jsonFilters: newJsonFilters,
  };
})
  .catch((error) => {
    handleErrorResponses(error);
    throw (error);
  });

// task status options
export const getTaskStatusesSuccess = (list) => ({
  type: types.GET_TASK_STATUSES_SUCCESS,
  list,
});
export function getTaskStatuses() {
  return (dispatch) => api.getStatuses().then((statuses) => {
    const taskStatuses = statuses.filter(({ tasks }) => tasks);
    const sortedTaskStatuses = sortBy(taskStatuses, ['statusName']);
    dispatch(getTaskStatusesSuccess(sortedTaskStatuses));
  }).catch((error) => {
    handleErrorResponses(error);
    throw (error);
  });
}
export function getTaskStatusPreCannedFilters() {
  return (_, getState) => {
    const stateJsonFilters = getAppliedFilters(getState());
    const taskStatusFromStateFilter = stateJsonFilters.find(({ key }) => key === 'taskStatus');
    const newJsonFilters = taskStatusFromStateFilter?.preCanned
      ? [taskStatusFromStateFilter]
      : [];

    return {
      jsonFilters: newJsonFilters,
    };
  };
}

// task assignee options
export const getTaskAssigneesSuccess = (list) => ({
  type: types.GET_TASK_ASSIGNEES_SUCCESS,
  list,
});
export const filterTaskAssignees = (list) => ({
  type: types.FILTER_TASK_ASSIGNEES,
  list,
});
export function getTaskAssigneesPreCannedFilters() {
  return (_, getState) => {
    const stateJsonFilters = getAppliedFilters(getState());
    const taskAssigneesFromStateFilter = stateJsonFilters.find(({ key }) => key === 'taskAssignee');
    const newJsonFilters = taskAssigneesFromStateFilter?.preCanned
      ? [taskAssigneesFromStateFilter]
      : [];

    return {
      jsonFilters: newJsonFilters,
    };
  };
}
export function getTaskAssigneesDropdownValues({ jsonFilters, dashboardFilters = {}, skipGlobalFilters }) {
  return (_, getState) => {
    const client = dashboardFilters.client || getState().dashboardFilters.client || [];
    const taskAssigneeAll = dashboardFilters.taskAssigneeAll || getState().dashboardFilters.taskAssigneeAll || [];

    const orgFilter = jsonFilters.find(({ key }) => key === 'client');
    const selectedOrgIds = hasFilterValue(orgFilter) ? orgFilter.value : client.map((e) => e.unitId);

    // if task assignee api call was made previously, get from redux, else make network request
    const requestTaskAssignees = skipGlobalFilters ? Promise.resolve(taskAssigneeAll) : api.getTaskAssignees(selectedOrgIds);

    return requestTaskAssignees.then((taskAssignees) => {
      const filterableOrgsIds = selectedOrgIds;

      // if there is/are selected org/s, filter task assignee that belongs to selected org/s
      let taskAssigneesBySelectedOrgs = taskAssignees.filter((assignee) => filterableOrgsIds.some((id) => assignee.unitIDs.includes(id)));

      // add user in task assignee dropdown list
      if (
        getState().router.location.pathname === '/tasks/my-tasks' &&
        getState().appliedFilters.currentFilterField === filterFields.taskModule &&
        !taskAssigneesBySelectedOrgs.some((s) => s.email === getState().user.email)
      ) {
        const myAssignee = { email: getState().user.email, uid: getState().user.uid, notFromAPI: true };
        taskAssigneesBySelectedOrgs = [myAssignee, ...taskAssigneesBySelectedOrgs];
      }

      const dropdownValues = {
        taskAssigneeAll,
        taskAssignee: taskAssigneesBySelectedOrgs,
      };
      let newJsonFilters = [];

      // update [currentDashboard.jsonFilters] task assignee selected values
      const { value: taskAssigneeFilterValues = [] } = getAppliedFilters(getState()).find(({ key }) => key === 'taskAssignee') || {};
      if (taskAssigneeFilterValues.length) {
        const updatedTaskAssigneeFilterValues = taskAssigneeFilterValues.filter((value) => taskAssigneesBySelectedOrgs.some((s) => s.uid === value));
        newJsonFilters = [{
          key: 'taskAssignee',
          value: updatedTaskAssigneeFilterValues,
        }];
      }

      // save to redux for future use
      if (!skipGlobalFilters) {
        dropdownValues.taskAssigneeAll = taskAssignees;
      }

      return {
        dropdownValues,
        jsonFilters: newJsonFilters,
      };
    }).catch((error) => {
      handleErrorResponses(error);
      throw (error);
    });
  };
}

// task reporter options
export const getTaskReportersSuccess = (list) => ({
  type: types.GET_TASK_REPORTERS_SUCCESS,
  list,
});
export const filterTaskReporters = (list) => ({
  type: types.FILTER_TASK_REPORTERS,
  list,
});
export function getTaskReportersDropdownValues({ jsonFilters, dashboardFilters = {}, skipGlobalFilters }) {
  return (_, getState) => {
    const client = dashboardFilters.client || getState().dashboardFilters.client || [];
    const taskReporterAll = dashboardFilters.taskReporterAll || getState().dashboardFilters.taskReporterAll || [];

    const orgFilter = jsonFilters.find(({ key }) => key === 'client');
    const selectedOrgIds = hasFilterValue(orgFilter) ? orgFilter.value : client.map((e) => e.unitId);

    // if task reporter api call was made previously, get from redux, else make network request
    const requestTaskReporters = skipGlobalFilters ? Promise.resolve(taskReporterAll) : api.getTaskReporters(selectedOrgIds);

    return requestTaskReporters.then((taskReporters) => {
      const filterableOrgsIds = selectedOrgIds;

      // if there is/are selected org/s, filter task reporter that belongs to selected org/s
      const taskReportersBySelectedOrgs = taskReporters.filter((reporter) => filterableOrgsIds.some((id) => reporter.unitIDs.includes(id)));

      const dropdownValues = {
        taskReporterAll,
        taskReporter: taskReportersBySelectedOrgs,
      };
      let newJsonFilters = [];

      // update [currentDashboard.jsonFilters] task reporter selected values
      const { value: taskReporterFilterValues = [] } = getAppliedFilters(getState()).find(({ key }) => key === 'taskReporter') || {};
      if (taskReporterFilterValues.length) {
        const updatedTaskReporterFilterValues = taskReporterFilterValues.filter((value) => taskReportersBySelectedOrgs.some((s) => s.uid === value));
        newJsonFilters = [{
          key: 'taskReporter',
          value: updatedTaskReporterFilterValues,
        }];
      }

      // save to redux for future use
      if (!skipGlobalFilters) {
        dropdownValues.taskReporterAll = taskReporters;
      }

      return {
        dropdownValues,
        jsonFilters: newJsonFilters,
      };
    }).catch((error) => {
      handleErrorResponses(error);
      throw (error);
    });
  };
}

// analysis options
export const getAnalysesSuccess = (list) => ({
  type: types.GET_ANALYSES_SUCCESS,
  list,
});
export const getEquipmentAnalysesSuccess = (list) => ({
  type: types.GET_EQUIPMENT_ANALYSES_SUCCESS,
  list,
});
export const filterAnalyses = (list) => ({
  type: types.FILTER_ANALYSES,
  list,
});

export const getAnalysesDropdownValues = ({ jsonFilters, dashboardFilters = {}, skipGlobalFilters }) => (dispatch, getState) => {
  const client = dashboardFilters.client || getState().dashboardFilters.client || [];
  const analysisAll = dashboardFilters.analysisAll || getState().dashboardFilters.analysisAll || [];
  const equipmentAnalysisAll = dashboardFilters.equipmentAnalysisAll || getState().dashboardFilters.equipmentAnalysisAll || [];

  const orgFilter = jsonFilters.find(({ key }) => key === 'client');
  const selectedOrgIds = hasFilterValue(orgFilter) ? orgFilter.value : client.map((e) => e.unitId);

  const requestAnalyses = skipGlobalFilters ? Promise.resolve(analysisAll) : api.getAnalyses({ UnitID: selectedOrgIds });
  const requestEquipmentAnalyses = skipGlobalFilters ? Promise.resolve(equipmentAnalysisAll) : api.getEquipmentAnalyses({ UnitID: selectedOrgIds });

  const promises = [
    requestAnalyses,
    requestEquipmentAnalyses,
  ];

  return Promise.all(promises)
    .then(([analyses, equipmentAnalyses]) => {
      const dropdownValues = {
        analysisAll: analyses,
        equipmentAnalysisAll: equipmentAnalyses,
      };
      return dropdownValues;
    }).catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
};

// currency options
export const getCurrenciesSuccess = (list) => ({
  type: types.GET_CURRENCIES_SUCCESS,
  list,
});
export const filterCurrencies = (list) => ({
  type: types.FILTER_CURRENCIES,
  list,
});
export const getCurrenciesDropdownValues = ({ jsonFilters }) => (_, getState) => {
  const buildingFilter = jsonFilters.find((e) => e.key === 'building');
  const { currencyAll = [] } = getState().dashboardFilters;

  // if building settings api call was made previously, get from redux, else make network request
  const allBuildingIds = getAllBuildingIds(getState);
  const hasCurrencyAll = currencyAll.length;
  const requestBuildingSettings = hasCurrencyAll ? Promise.resolve(currencyAll) : api.getBuildingSettings(allBuildingIds);

  return requestBuildingSettings.then((buildingCurrencies) => {
    const selectedBIDs = buildingFilter && buildingFilter.value || [];
    const availableBIDs = getAvailableBuildingIds(getState);
    const filterableBuildingIds = selectedBIDs.length ? selectedBIDs : availableBIDs;

    // if there is/are selected building/s, filter currency that belongs to selected org/s
    const currenciesBySelectedBuildings = buildingCurrencies.filter((buildingCurrency) => filterableBuildingIds.some((id) => id === buildingCurrency.bid));
    const uniqCurrencies = uniqBy(currenciesBySelectedBuildings, 'isoCurrencySymbol').map(({ isoCurrencySymbol }) => ({ id: isoCurrencySymbol, label: isoCurrencySymbol }));
    const sortedCurrencies = sortBy(uniqCurrencies, 'id');

    const dropdownValues = {
      currencyAll,
      currency: sortedCurrencies,
    };
    let newJsonFilters = [];

    // update [currentDashboard.jsonFilters] currency selected values
    const { value: currencyFilterValues = [] } = getAppliedFilters(getState()).find(({ key }) => key === 'currency') || {};
    if (currencyFilterValues.length) {
      const newCurrencyAppliedFilters = currencyFilterValues.filter((currencyId) => sortedCurrencies.some(({ id }) => id === currencyId));
      newJsonFilters = [{
        key: 'currency',
        value: newCurrencyAppliedFilters,
      }];
    }

    // save to redux for future use
    if (!hasCurrencyAll) {
      dropdownValues.currencyAll = buildingCurrencies;
    }

    return {
      dropdownValues,
      jsonFilters: newJsonFilters,
    };
  }).catch((error) => {
    handleErrorResponses(error);
    throw (error);
  });
};

// data source options
export const getDataSourcesSuccess = (list) => ({
  type: types.GET_DATA_SOURCES_SUCCESS,
  list,
});
export const filterDataSources = (list) => ({
  type: types.FILTER_DATA_SOURCES,
  list,
});
export function getDataSourcesDropdownValues({ jsonFilters, dashboardFilters = {}, skipGlobalFilters }) {
  return (_, getState) => {
    const client = dashboardFilters.client || getState().dashboardFilters.client || [];
    const dataSourceAll = dashboardFilters.dataSourceAll || getState().dashboardFilters.dataSourceAll || [];

    const orgFilter = jsonFilters.find(({ key }) => key === 'client');
    const selectedOrgIds = hasFilterValue(orgFilter) ? orgFilter.value : client.map((e) => e.unitId);
    const requestDataSources = skipGlobalFilters ? Promise.resolve(dataSourceAll) : api.getDataSources({ UnitID: selectedOrgIds });

    return requestDataSources.then((dataSources) => {
      const filterableOrgsIds = selectedOrgIds;

      // if there is/are selected org/s, filter data source that belongs to selected org/s
      const dataSourcesBySelectedOrgs = dataSources.filter((datasource) => filterableOrgsIds.some((id) => datasource.unitId === id));

      const dropdownValues = {
        dataSourceAll,
        dataSource: dataSourcesBySelectedOrgs,
      };
      let newJsonFilters = [];

      // update [currentDashboard.jsonFilters] data source selected values
      const { value: dataSourceFilterValues = [] } = getAppliedFilters(getState()).find(({ key }) => key === 'dataSource') || {};
      if (dataSourceFilterValues.length) {
        const updatedDataSourcesFilterValues = dataSourceFilterValues.filter((value) => dataSourcesBySelectedOrgs.some((s) => s.dsid === value));
        newJsonFilters = [{
          key: 'dataSource',
          value: updatedDataSourcesFilterValues,
        }];
      }

      dropdownValues.dataSourceAll = dataSources;

      return {
        dropdownValues,
        jsonFilters: newJsonFilters,
      };
    }).catch((error) => {
      handleErrorResponses(error);
      throw (error);
    });
  };
}

/**
 * End of Dashboard Filters
 */
