const columns = [
  {
    'title': 'Equipment',
    'field': 'equipmentName',
    'width': 19,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 1,
  },
  {
    'title': 'Building',
    'field': 'buildingName',
    'width': 17,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 2,
  },
  {
    'title': 'Equipment Class',
    'field': 'equipmentClassName',
    'width': 17,
    'show': true,
    'filter': 'text',
    'orderIndex': 3,
  },
  {
    'title': 'Equipment Type',
    'field': 'equipmentTypeName',
    'width': 17,
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
  },
  {
    'title': 'Active',
    'field': 'active',
    'width': 8,
    'show': true,
    'filter': 'text',
    'orderIndex': 5,
  },
  {
    'title': 'Visible',
    'field': 'visible',
    'width': 8,
    'show': true,
    'filter': 'text',
    'orderIndex': 6,
  },
  {
    'title': 'Configuration Status',
    'field': 'configurationStatusName',
    'width': 14,
    'show': true,
    'filter': 'text',
    'orderIndex': 7,
  },
  {
    'title': 'Equipment ID',
    'field': 'eid',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 8,
  },
  {
    'title': 'Equipment Notes',
    'field': 'equipmentNotes',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 9,
  },
  {
    'title': 'CMMS Reference ID',
    'field': 'cmmsReferenceId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 10,
  },
  {
    'title': 'CMMS Location ID',
    'field': 'cmmsLocationId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 11,
  },
  {
    'title': 'CMMS Site ID',
    'field': 'cmmsSiteId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 12,
  },
  {
    'title': 'CMMS Link',
    'field': 'cmmsLink',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 13,
  },
];

export default columns;
